.previewContainer {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 2%;
  letter-spacing: 1px;
  font-size: 16px !important;
}

.documents {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 1.3% 2% 2% 2%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.isDisabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.previewNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.05);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 2px solid white;
}

.previewNav > div {
  padding: 1.5% 2%;
  cursor: pointer;
  font-size: 120%;
}

/* / */
.flexRowJustify {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Container {
  padding: 1%;
  letter-spacing: 1px;
  background-color: black;
  border-radius: 10px;
  margin-top: 2%;
}

.heading1 {
  font-size: 110%;
}

.pdfView {
  height: 150vh;
  margin-top: 2%;
  border-radius: 10px;
}

.btnDiv {
  padding: 0% 2.5%;
  display: flex;
  justify-content: flex-end;
}

.btnDiv > button {
  padding: 1% 4%;
  font-size: 100%;
  letter-spacing: 1px;
  color: white;
  background-color: #42a5f5;
  border-radius: 5px;
}

.previewDiv {
  display: flex;
  flex-direction: row;
}

.previewDiv > div {
  flex: 1;
  padding: 1%;
  letter-spacing: 1px !important;
  font-size: 14px !important;
}

.colorBlue {
  color: #42a5f5;
}

.previewList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2% 0%;
}

.previewList > div:nth-child(1) {
  flex: 40%;
}

.previewList > div:nth-child(2) {
  flex: 60%;
  text-align: right;
}

.Heading {
  margin-left: 1%;
  margin-top: 1%;
}

.Row {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  letter-spacing: 1px;
  justify-content: space-between;
}

.Row > div {
  flex: 1;
  align-items: center;
}

.Column {
  padding: 0% 1%;
}

.List {
  margin: 3% 0%;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 2%;
  border-radius: 5px;
}

.List > div {
  flex: 1;
}

.List > div:nth-child(2) {
  color: #42a5f5;
  text-align: right;
}

.TagDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.TagDiv > div {
  flex: 32%;
  margin-bottom: 2%;
  padding: 0% 1%;
}

.TagDiv > div > span {
  display: flex;
  padding: 1%;
  border-radius: 5px;
  background-color: #42a5f5;
}

.TagDiv > div > span:nth-child() {
  display: flex;
  padding: 1%;
  border-radius: 5px;
  background-color: red;
}

/* Document Properties */

.InputDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.InputDiv > div {
  flex: 50%;
  margin: 1% 0%;
}

.selectedListDiv {
  margin: 1% 0%;
  display: flex;
  flex-direction: column;
}

.selectedListRow {
  display: flex;
  justify-content: space-between;
  padding: 0.7% 1%;
  margin: 0.5% 0%;
  border-radius: 5px;
  background-color: #42a5f5;
}

.selectedListIcon > span {
  cursor: pointer;
  margin-left: 15px;
}
