.profileWrapper {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 2%;
  padding: 2%;
  font-size: 14px;
  letter-spacing: 1px;
  background: rgba(30, 30, 30, 1);
}

.Content {
  border-radius: 5px;
  display: block;
  letter-spacing: 1px;
  margin-bottom: 2%;
  padding: 2%;
  font-size: 14px;
  background: rgba(30, 30, 30, 1);
}

.Content > div:nth-child(1) {
  margin-bottom: 20px;
}

.Content .title {
  font-size: 1.25rem;
  color: #42a5f5;
  cursor: pointer;
}

.link {
  color: #42a5f5;
  cursor: pointer;
}

.link:hover {
  opacity: 0.8;
}

.Parent {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.Parent > div {
  width: 31%;
  border-radius: 5px;
  background-color: black;
  margin: 1%;
  padding: 18px;
}

.Parent > div:hover {
  transform: scale(1.03);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Parent .title2 {
  color: #42a5f5;
  font-size: 1.1rem;
  text-align: center;
  margin: 10px 0px;
  cursor: pointer;
}

.Parent .title3 {
  margin-bottom: 10px;
}

.Content1 {
  border-radius: 5px;
  letter-spacing: 1px;
  margin-bottom: 2%;
  padding: 2%;
  font-size: 14px;
  background: rgba(30, 30, 30, 1);
  display: flex;
}

.Content1 > div:nth-child(1) {
  /* width: 60%; */
}

.Content1 > div:nth-child(2) {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Content1 .title1 {
  font-size: 1.25rem;
  color: #42a5f5;
  cursor: pointer;
  margin-bottom: 10px;
}

.Content1 a {
  color: #42a5f5;
  text-decoration: none;
  margin-right: 10px;
}
