.show {
  height: auto;
  max-height: 9999px;
  display: block;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.description {
  color: yellow;
  /* max-height: 0px; */
  display: none;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}
