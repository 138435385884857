/* .NewOverview {
  border: 1px solid red;
} */

.Head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin-bottom: 5px;
}

.Head > div:nth-child(1) {
  font-size: 16px;
}

.Head > div:nth-child(2) {
  align-items: center;
}

.status {
  width: 120px;
  text-align: center;
}

.status > div {
  border-radius: 5px;
}

.IconDiv {
  display: flex;
}

.List {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.4%;
  justify-content: space-between;
}

.Single {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

.head {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid white;
  padding-bottom: 8px;
  margin-bottom: 8px;
  font-size: 16px;
}

.Single2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.Single2 > div {
  width: 50%;
}

.Parent {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 20vh;
}
