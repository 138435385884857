.panel {
    background-color: black;
    margin-bottom: 2%;
    border-radius: 10px;
    padding: 2%;
    margin-top: 1%;
}
.panelHeading {
   font-size: 14px;
}
.overviewListItem{
    display: flex;
    width: 50%;
    justify-content:center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    margin: 0.5%;
    padding-left:1.5%;
    padding-top:1%;
    padding-bottom:1%;
    padding-right:1.5%;
    border-radius: 5px;
    font-size: 12px;
}
.overViewListFirstTile{
    color: #218EEB;
    width:45%;
    text-align: left;
    margin-left:10%;   
}
.colon{
    width:10%;
    color: #218EEB;
}
.overViewListSecondTile{
    width:45%;
    margin-left:10%;
}