.invoice-tableHeader {
  display: flex;
  background-color: #343434;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid white;
  font-size: 14px;
}

.invoice-tableHeader > div {
  text-align: center;
  padding: 1% 0%;
  border-right: 1px solid white;
}

.invoice-tableHeader > div:last-child {
  border-right: none !important;
}

.invoice-tablebody {
  display: flex;
  /* margin: 0%  1% 0% 1%; */
  border: 1px solid white;
  font-size: 14px;
}
.invoice-tablebody > div:first-child {
  cursor: pointer !important;
}

.invoice-tablebody > div:first-child:hover {
  color: #42a5f5;
}


.invoice-tablebody > div {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1% 0%;
  border-right: 1px solid white;
  word-break: break-all;
}
/* .invoice-tablebody > div:hover {
  color: #42a5f5;
  cursor: pointer;
} */

.invoice-tablebody > div:last-child {
  border-right: none !important;
}

.invoice-tableb {
  display: flex;
  /* margin: 0%  1% 0% 1%; */
  border: 1px solid white;
  font-size: 14px;
}

.invoice-tableb > div {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1% 0%;
  border-right: 1px solid white;
  word-break: break-all;
}
/* .invoice-tableb > div:hover {
  color: #42a5f5;
  cursor: pointer;
} */

.invoice-tableb > div:last-child {
  border-right: none !important;
}

.flexRowJustify {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}
