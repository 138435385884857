.Details {
  padding: 1% 0%;
  margin: 1% 0%;
}

.column {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 12px !important;
  letter-spacing: 1px !important;
  word-wrap: nowrap !important;
  white-space: nowrap;
}

.column1 {
  display: flex;
  flex: 32%;
  flex-direction: column;
  background: #1e1e1e;
  border-radius: 10px;
  margin: 0.5%;
}

.column2 {
  display: flex;
  width: 200px;
  flex-direction: column;
  margin: 0.5%;
}

.column2 > button {
  padding: 2% 0%;
  border-radius: 5px;
  background-color: #42a5f5;
  color: #fff;
}

.columnContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5%;
  padding: 1% 1%;
}
