/* button:hover {
  opacity: 0.5;
} */

/* button{
    margin: 1%;
    padding: 1%;
    color: white;
    border: none;
    background-color: #42a5f5;
    border-radius: 5px;
    font-size: 14px;
}  */

.loanid {
  color: #42a5f5;
  text-decoration: underline;
}
.loanid:hover {
  color: #0634ce;
}
