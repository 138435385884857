.searchDiv {
  display: flex;
  justify-content: space-between;
  letter-spacing: 1px;
}

.searchDiv > div:nth-child(1) {
  flex: 0.5;
}

.filterDiv {
  display: flex;
  flex-direction: row;
}

.filterDiv > div {
  flex: 1;
  padding: 0% 1%;
  padding: 1% 0%;
  margin-bottom: 1%;
  border-radius: 30px;
  margin-right: 1%;
  text-align: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.selectOption {
  background: #42a5f5;
}

.selectOption:hover {
  opacity: 0.5;
}

.notSelectOption {
  background-color: grey;
}

.companyType {
  cursor: pointer;
  position: absolute;
  font-size: 14px;
  width: 100px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 9%;
  margin-bottom: 10px;
  border-radius: 4px;
  letter-spacing: 1px;
  background: rgba(255, 161, 0, 1);
}
.supplierType {
  cursor: pointer;
  position: absolute;
  font-size: 14px;
  width: 100px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 9%;
  margin-bottom: 10px;
  border-radius: 4px;
  letter-spacing: 1px;
  background: purple;
}

.searchDiv > div:nth-child(2) {
  flex: 0.45;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.inputSearchDiv > input {
  width: 450px;
  height: 35px;
  border: none !important;
  border-radius: 5px;
  padding-left: 15px;
  outline: none;
  font-size: 14px;
  letter-spacing: 1px;
}

.buttonDiv > button {
  padding: 10px 40px;
  background-color: #42a5f5;
  letter-spacing: 1px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
}

.scrollDiv::-webkit-scrollbar {
  width: 10px;
  border-radius: 30px;
}

.scrollDiv::-webkit-scrollbar-thumb {
  background: #42a5f5 !important;
  border-radius: 30px;
}
