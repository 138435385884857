.openChat {
  top: 50%;
  right: 0;
  z-index: 999;
  position: absolute;
  cursor: pointer;
}

.container {
  flex: 1;
}

.wrapper {
  padding: 2%;
  background-color: black;
  border-radius: 10px;
  margin-bottom: 15px;
  flex: 1;
}

.containerWrapper {
  padding: 2%;
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  flex: 1;
}

.actionBarStyled {
  flex: 1;
  padding: 1.5% 1.5% 0% 1.5% !important;
  margin-bottom: 0px !important;
}

/* Start --  Overview */
.heading1 {
  font-weight: 400;
  font-size: 20px;
  line-height: 15px;
  color: #ffffff;
}

.uploadInvoiceBtn {
  background: #42a5f5;
  border-radius: 5px;
  font-style: normal;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  padding: 0.2rem 0.5rem;
}

.card {
  display: flex;
  flex: 47%;
  flex-direction: column;
  background: #1e1e1e;
  border-radius: 10px;
  margin: 5px 10px 5px 0px;
}

.content {
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem;
  letter-spacing: 1px;
}
/* End --  Overview */

/* Start --  Invoice */
.invoiceHeader {
  padding: 20px 16px;
  background-color: black;
  border-radius: 10px;
  margin-bottom: 15px;
  flex: 1;
}
.invoiceTopHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.heading2 {
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  margin: 15px 0px;
}
.active {
  background-color: blue;
}
.searchDiv {
  display: flex;
  line-height: 2.6em;
  letter-spacing: 1px;
  font-size: 14px;
  align-items: center;
}
.searchDiv > div {
  flex: 2;
  text-align: center;
  align-items: center;
  margin: 0px 2px;
  border-radius: 30px;
  padding: 0px 1px;
  clear: both;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  text-transform: uppercase;
}
.searchDiv > div:hover {
  background: #42a5f5;
}
.activeDiv {
  background: #42a5f5;
}
.nonActiveDiv {
  border: 1px solid #42a5f5;
}

.searchDiv > input {
  flex: 4;
  margin: 0px 2px;
  height: 41px;
  width: 300px;
  border: none;
  margin-left: 5px;
  border-radius: 5px;
  padding-left: 5px;
  font-size: 20px;
}
.searchDiv > input:focus {
  outline: none;
}
.wrapperTitle {
  padding-left: 10px;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 200;
}
.tableContainer {
  padding: 10px;
}

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  font-size: 14px !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

.Table > tbody > tr:hover {
  color: #42a5f5;
}

.invoiceNavigation {
  color: #42a5f5;
}

/* End --  Invoice */

/* Start --  invoice Summary */
.summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  margin: 10px 0px;
  letter-spacing: 1px;
}
.summaryWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  margin: 5px;
  background-color: #2e2e2e;
  border-radius: 8px;
  font-size: 16px;
}

/* End --  invoice Summary */

/* start -- invoice Details */

.detailsForm {
  margin: 10px 0px;
  border-radius: 5px;
  background-color: black;
  padding: 15px;
}

.form {
  display: grid;
  grid-template-columns: 49% 49%;
  justify-content: space-between;
}
.inputField {
  margin: 15px 5px;
  flex: 48% !important;
}

.inputField input {
  border-radius: 6px;
  margin-top: -10px;
  width: 96%;
  height: 51px;
  outline: none;
  background: none;
  z-index: -11;
  border: 0.2px solid #42a5f5;
  color: white;
  padding-left: 20px;
  font-size: 18px;
}

.inputField label {
  margin-top: -20px;
  position: absolute;
  margin-left: 30px;
  background: #010915;
  z-index: 10;
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
  color: #42a5f5;
  position: relative;
}

.detailsBtnDiv {
  display: flex;
  justify-content: flex-end;
}

.detailsBtnDiv button {
  padding: 10px 50px;
  margin: 5px;
  background: #42a5f5;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
/* End --  invoice Details */

@media (min-width: 666px) and (max-width: 899px) {
  .container {
    padding: 15px !important;
    margin: 0 auto;
  }
  .containerWrapper {
    padding: 15px;
    flex: 1;
  }
  .actionBarStyled {
    font-size: 14px;
    letter-spacing: 1px;
    padding: 15px 15px 0px 15px !important;
    margin-bottom: 0px !important;
  }

  /* Start --  Overview */
  .heading1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }

  .uploadInvoiceBtn {
    font-size: 14px;
    text-align: center;
    padding: 0.1rem 0.6rem;
  }

  .content {
    font-weight: 400;
    font-size: 12px;
    margin: 0.4rem;
    letter-spacing: 1px;
  }
  /* End --  Overview */

  /* Start --  Invoice */
  .invoiceHeader {
    padding: 14px 14px;
    margin-bottom: 15px;
    flex: 1;
  }

  .heading2 {
    font-size: 14px;
    line-height: 10px;
    margin: 15px 0px 15px 0px;
  }

  .searchDiv {
    display: grid;
    grid-template-columns: 49% 49%;
    line-height: 1.8rem;
    font-size: 12px;
  }
  .searchDiv > div {
    margin: 2px;
  }

  .searchDiv > input {
    margin: 2px;
    height: 30px;
    width: 300px;
    border: none;
    margin-left: 5px;
    padding-left: 5px;
    font-size: 14px;
  }
  .wrapper {
    padding: 10px;
  }
  .wrapperTitle {
    padding-left: 10px;
    letter-spacing: 1px;
    font-size: 14px;
  }

  .tableContainer {
    padding: 10px;
  }
  .tableContainer table {
    font-size: 14px !important;
  }

  /* End --  Invoice */
}

@media (min-width: 450px) and (max-width: 665px) {
  .container {
    padding: 12px !important;
  }

  .containerWrapper {
    padding: 12px;
    flex: 1;
  }
  .actionBarStyled {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 15px 15px 0px 15px !important;
    margin-bottom: 0px !important;
  }

  /* Start --  Overview */
  .heading1 {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .uploadInvoiceBtn {
    font-size: 12px;
    text-align: center;
  }

  .content {
    font-weight: 400;
    font-size: 12px;
    margin: 0.4rem;
    letter-spacing: 1px;
  }
  /* End --  Overview */

  /* Start --  Invoice */
  .invoiceHeader {
    padding: 10px;
    margin-bottom: 10px;
    /* flex: 1; */
    flex-wrap: wrap;
  }

  .heading2 {
    font-size: 12px;
    line-height: 10px;
    margin: 10px 0px 10px 0px;
  }

  .searchDiv {
    display: grid;
    grid-template-columns: 48% 48%;
    line-height: 1.6rem;
    font-size: 12px;
  }
  .searchDiv > div {
    margin: 2px;
  }

  .searchDiv > input {
    margin: 2px;
    height: 30px;
    width: 250px;
    margin-left: 5px;
    padding-left: 5px;
    font-size: 12px;
  }
  .wrapper {
    padding: 10px;
    flex-wrap: wrap;
  }
  .wrapperTitle {
    font-size: 12px;
  }

  .tableContainer {
    padding: 10px;
  }
  .tableContainer table {
    font-size: 12px !important;
    overflow: scroll !important;
    flex-wrap: wrap;
  }
  .tableContainer table > thead th,
  td {
    padding: 5px 2px !important;
  }

  .tableContainer table > tbody th,
  td {
    padding: 2px !important;
  }

  /* End --  Invoice */
}

@media (min-width: 350px) and (max-width: 449px) {
  .container {
    padding: 12px !important;
  }
  .containerWrapper {
    padding: 12px;
    flex: 1;
  }
  .actionBarStyled {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 10px;
    letter-spacing: 1px;
    padding: 15px 15px 0px 15px !important;
    margin-bottom: 0px !important;
  }
  .card {
    display: flex;
    flex: 100%;
  }

  /* Start --  Overview */
  .heading1 {
    font-weight: 400;
    font-size: 10px;
  }

  .uploadInvoiceBtn {
    font-size: 10px;
    text-align: center;
    padding: 7px 4px;
    line-height: 8px;
    border: none;
  }

  .content {
    font-weight: 400;
    font-size: 10px;
    margin: 0.3rem;
    letter-spacing: 1px;
  }
}
