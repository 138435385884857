
.navbar {
    display: flex;
    justify-content: space-around;
    padding: 0.5% !important;
    margin-bottom: 0px !important;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    list-style: none;
}

.navbar > a {
    color: #fff;
    flex: 1;
    text-align: center;
    text-decoration: none;
    padding: 1.2% 1.2% 1.2% 1.2%;
}

.navbar > a:hover{
    color: #218eeb;
    cursor: pointer;
}

.navbar > button {
    margin: 0 16px;
}

.active{
    color: #218eeb !important;
    font-size: 100% !important;
    border-bottom: 2px solid #218eeb !important;
}

.notActive{
    color: white !important;
    font-size: 100% !important;
    border-bottom: 2px solid white !important;
}

