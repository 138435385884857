.Input {
  height: 41px;
  border: none;
  border-radius: 5px;
  padding-left: 5px;
  font-size: 20px;
}
.Input:focus {
  outline: none;
}

.multiSelect {
  display: flex;
  position: relative;
}

.multiSelect > div {
  flex: 1;
}

.multiSelect > label {
  display: block;
  color: white;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  left: 16px;
  top: -8px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 10px;
  z-index: 1;
  background-color: black;
  padding: 0px 5px;
  letter-spacing: 1px;
  color: #42a5f5;
}
