.ToogleSelect {
  margin: 1% 0%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.ToogleSelect > div:first-child {
  margin-left: 0%;
}

.ToogleSelect > div:last-child {
  margin-right: 0%;
}

.ToogleSelect > div {
  flex: 1;
  text-align: center;
  margin: 0% 0.5%;
  padding: 0.7% 3px;
  border-radius: 15px;
  color: #fff;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 12px;
}

.ToogleSelect > div:hover {
  background-color: #42a5f5;
}

.isActive {
  background-color: #42a5f5;
}

.notActive {
  background-color: gray;
}

.toogleTab {
  background-color: black;
  margin-bottom: 1%;
  padding: 1% 1%;
  border-radius: 10px;
  letter-spacing: 1px;
  font-size: 14px !important;
}

.singleToogleTab {
  background-color: black;
  margin-bottom: 1%;
  padding: 1% 1%;
  border-radius: 10px;
  letter-spacing: 1px;
  font-size: 14px !important;
}

.toogleTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
