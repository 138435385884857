.UploadForm {
  padding: 1%;
}

.Upload {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-color: #2e2e2e;
}

.Upload label {
  flex: 1;
}

.UploadDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #d7dadf;
  height: 400px;
}

.UploadDiv > svg {
  color: #42a5f5;
}
