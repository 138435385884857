/* Start --  invoice Summary */
.summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1%;
  margin: 1% 0%;
  letter-spacing: 1px;
}
.summaryWrapper {
  display: flex;
  justify-content: space-between;
  padding: 1.5%;
  margin: 1%;
  background-color: #2e2e2e;
  border-radius: 10px;
  font-size: 16px;
}
