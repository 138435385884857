.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  font-size: 14px !important;
  white-space: nowrap !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

.Table > tbody > tr > td:nth-child(1) {
  cursor: pointer !important;
}

.Table > tbody > tr > td:nth-child(1):hover {
  color: #42a5f5;
}
