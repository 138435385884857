.column {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px !important;
}

.columnList {
  display: flex;
  flex: 47%;
  flex-direction: column;
  background: #2e2e2e;
  border-radius: 10px;
  margin: 0.5%;
}

.columnContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5%;
  padding: 0% 1%;

  letter-spacing: 1px !important;
}
