.UserManagement {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.InputList {
  flex: 49%;
  margin-bottom: 2%;
}

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  font-size: 14px !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}
