.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  font-size: 14px !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

/* .Table > tbody > tr > td:hover {
  color: #42a5f5 !important;
} */

/* .tableRow:hover{
  color: #42a5f5 !important;
} */

.columnHover {
  color: #42a5f5 !important;
  text-decoration: underline;
}
/* .columnHover:hover {
  color: #0634ce !important;
} */

.AccountSchedule {
  margin-bottom: 1%;
  color: #42a5f5;
  cursor: pointer;
  text-decoration: underline;
}

/* Loan Details */

.loanDetails {
  padding: 1% 0%;
  margin: 1% 0%;
  border: 1px solid red;
}

.column {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 12px !important;
  letter-spacing: 1px !important;
  word-wrap: nowrap !important;
  white-space: nowrap;
}

.column1 {
  display: flex;
  flex: 32%;
  flex-direction: column;
  background: #1e1e1e;
  border-radius: 10px;
  margin: 0.5%;
}

.column2 {
  display: flex;
  width: 200px;
  flex-direction: column;
  margin: 0.5%;
}

.column2 > button {
  padding: 2% 0%;
  border-radius: 5px;
  background-color: #42a5f5;
  color: #fff;
}

.columnContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5%;
  padding: 1% 1%;
}

/* Form Div */

.FormDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.FormDiv > div {
  flex: 49%;
  margin-bottom: 2%;
}

/* Start --  Invoice */
.invoiceHeader {
  padding: 20px 16px;
  background-color: black;
  border-radius: 10px;
  margin-bottom: 15px;
  flex: 1;
}
.invoiceTopHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}
.heading2 {
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  margin: 15px 0px;
}
.active {
  background-color: blue;
}

.searchDiv {
  display: flex;
  letter-spacing: 1px;
  font-size: 14px;
  align-items: center;
}

.searchDiv > div {
  flex: 1;
  margin-right: 10px;
}

.searchDiv > input {
  flex: 1;
  border-radius: 5px;
  outline: none;
  border: none;
  letter-spacing: 1px;
  height: 40px;
}

.searchDiv input:focus {
  outline: none;
}

.searchDiv1 {
  margin-top: 10px;
  display: flex;
  letter-spacing: 1px;
  font-size: 14px;
  align-items: center;
}

.searchDiv1 > div {
  width: 24.2%;
  margin-right: 10px;
}

.wrapperTitle {
  padding-left: 10px;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 200;
}
.tableContainer {
  padding: 10px;
}

/* .Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

.Table > tbody > tr:hover {
  color: #42a5f5;
} */

/* .newTable {
  border: 1px solid yellow;
} */

.uploadInvoiceBtn {
  background: #42a5f5;
  border-radius: 5px;
  font-style: normal;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none !important;
  padding: 0.2rem 0.5rem;
}

.newTableHead {
  display: flex;
  flex-direction: row;
  background-color: #343434;
}

.newTableHead > div {
  flex: 1;
  border: 1px solid white;
  text-align: center;
  padding: 1% 0%;
}

.newTableBody {
  display: flex;
  flex-direction: row;
}

.newTableBody > div {
  flex: 1;
  text-align: center;
  border: 1px solid white;
  padding: 1% 0%;
}

.tableText {
  padding: 1% 0%;
}

.invoiceNavigation {
  color: #42a5f5;
}

/* End --  Invoice */
.summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1%;
  margin: 1% 0%;
  letter-spacing: 1px;
}
.summaryWrapper {
  display: flex;
  justify-content: space-between;
  padding: 1.5%;
  margin: 1%;
  background-color: #2e2e2e;
  border-radius: 10px;
  font-size: 16px;
}
.filterButton {
  display: flex;
  width: 30%;
  border: 1px solid #42a5f5;
  padding: 0.5%;
  justify-content: center;
  border-radius: 30px;
  margin: 1%;
}
.filterButton:hover {
  background-color: #42a5f5;
}
.activeButton {
  margin: 2%;
  background-color: #42a5f5;
  border: 1px solid white;
  color: #fff;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 5px;
  height: 2.5rem;
}
.activeButton:hover {
  opacity: 0.5;
}

/* STart Upload */

.UploadForm {
  padding: 1%;
}

.Upload {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-color: #2e2e2e;
}

.Upload label {
  flex: 1;
}

.UploadDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #d7dadf;
  height: 400px;
}

.UploadDiv > svg {
  color: #42a5f5;
}

/* END Upload */
