.LinkBuyer{
    padding: 15px;
}
.buttonDiv{
    display: flex;
    margin: 10px 0px;
    justify-content: flex-end;
}
.buttonDiv button{
    padding: 10px 30px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin:5px;
}

.Buyer{
    padding: 15px 20px;
    border-radius: 5px;
    margin: 15px 0px;
    background-color: black;
}
.flexbox{
    display: flex;
}
.toggleHeading{
    display: flex;
    justify-content: space-between;
    letter-spacing: 2px;
    font-size: 18px;
}

.flexstart{
    display: flex;
    justify-content:flex-start;
    letter-spacing: 1px;
}

.activeBtn{
    background: #42A5F5;
    color: #FFFF;
}
.btn{
    background: #FFFF;
    color: #42A5F5;
}

.buyerDetails{
   display: flex;
   margin: 20px 0px;
   letter-spacing: 1px;
}

.buyerDetails > div:nth-child(1){
    margin-right: 15px;
    min-width: 250px;
}
.buyerDetails > div > div {
    margin: 10px 0px;
}
.div1{
    padding: 15px;
}
.div2{
    padding: 15px;
    background-color: black;
    border-radius: 5px;
    margin: 5px 0px;
    display: flex;
    flex-direction: column;
}
.FinancingForm{
    margin: 10px 0px;
    border-radius: 5px;
    background-color: black;
    padding: 15px;
}
.formHeading{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #42A5F5;
}

.form{
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;

}
.inputField{
    margin: 15px 5px;
    flex: 48% !important;
}
  
.inputField  input[type="text"]{
    border-radius: 6px;
    margin-top: -10px;
    width: 96%;
    height: 51px;
    outline: none;
    background: none;
    z-index: -11;
    border: 0.2px solid #42A5F5;
    color:white;
    padding-left:20px;
    font-size: 18px;
}

.inputField  input[type="date"]{
    border-radius: 6px;
    margin-top: -10px;
    width: 92%;
    height: 51px;
    outline: none;
    z-index: -11;
    border: 0.2px solid #42A5F5;
    color:white;
    padding-left:20px;
    font-size: 18px;  
    background: transparent; 
    padding-right: 20px !important;
}
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(100%);
  }


.inputField  label{
    margin-top: -20px;
    position: absolute;
    margin-left:30px;
    background:#010915;
    z-index: 10;
    font-size:15px;
    padding-left:5px;
    padding-right:5px;
    color:#42A5F5;
    position: relative;
}



.inputRadio{
    display:flex;
    flex-direction: column;
    margin: 20px 10px;
}
.radioHeading{
    color: white !important;
    letter-spacing: 1px !important;
}
.inputRadio div{
    margin: 10px 0px;
    font-size: 24px;
    color:#42A5F5;
}
.radioInput{
    padding: 10px 0px;
}

.radioInput input{
   transform: scale(2);
   margin-right: 10px;
   border: 1px solid #42A5F5;
}
.radioInput span{
    border: 1px solid #42A5F5;
    border-radius: 5px;
    padding: 10px;
}

.mark{
    position:absolute;
    top: -3px;
    right: -12px;
    background-color: black;
    color: red;
}
.form > button{
    max-width: 210px;
    margin-left: 10px;
    height: 40px;
    background-color: #42A5F5;
    color: white;
    border-radius: 5px;
    letter-spacing: 1px;
    font-size: 18px;
    cursor: pointer;
}
.submited{
    padding: 20px 0px;
    letter-spacing: 1px;
    color: rgb(51, 195, 12);
    font-size: 22px;
}
.financeBtn {
    display: flex;
    justify-content: flex-end;
    margin: 50px 0px 10px 10px;
}

.financeBtn > button{
    display: flex;
    background-color: #42A5F5;
    cursor: pointer;
    letter-spacing: 1px;
    color: #fff !important;
}