.inviteContainer{
    /* border: 1px solid red; */
    background-color: #121212;
    padding: 2%;
    border-radius: 5px;
    letter-spacing: 1px;
}

.container{
    /* border: 1px solid blue; */
    width: 60%;
    margin: 0 auto;
    padding: 2%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imgDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin: 1% 0%;
}

.imgDiv img{
  width: 30%;
  align-items: center;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.formStyle{
    /* border: 1px solid green; */
    background-color: #1E1E1E;
    border-radius: 5px;
    padding: 4%;
    margin : 2% 0%;
    display: flex;
    flex-direction: column;
    width: 98%;
}

.inputDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1% 0%;
    margin: 1% 0%;
    align-items: center;
}

.inputDiv div{
  flex: 1;
}

.inputDiv input{
    border-radius: 5px;
    /* margin-top: -10px; */
    /* margin-right:20px; */
    width:100%;
    height: 50px;
    outline: none;
    background:  rgb(25, 25, 25);
    /* z-index: -11; */
    font-size: 16px;
    border: 0.2px solid #42A5F5;
    color:#42A5F5;
    padding-left: 2%; 
}

.iconDiv svg{
    margin-right:4%;
    cursor: pointer;
}

.iconDiv svg:nth-child(1){
    color:#fff;
    background:
    linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
    radial-gradient(#25d366 60%,transparent 0);
}

.copyIconDiv{
    position: relative;
}

.copyIcon{
    position: absolute;
    top: 14px;
    right: 0;
    color: #42A5F5;
    cursor: pointer;
}

.buttonDiv{
    align-items: center;
    margin: 1% 0%;
}

.buttonDiv button{
   width: 200px;
   height: 60px;
   color: #fff;
   font-size: 16px;
   background-color: #42A5F5;
   border-radius: 5px;
   border: none !important;
   cursor: pointer;
}

.text3{
    font-size: 16px;
}
.text2{
    font-size: 18px;
    margin: 2% 0%;
}
.text1{
    font-size: 20px;
}