* {
  padding: 0;
  margin: 0;
}

.edit {
  padding: 1%;
  font-size: 14px !important;
  letter-spacing: 1px;
}

.Checkbox {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.profileForm {
  letter-spacing: 1px;
  word-spacing: 1px;
}

.profileForm textarea {
  border-radius: 5px;
  margin-top: -10px;
  width: -webkit-fill-available;
  height: 92px;
  outline: none;
  background: none;
  z-index: -11;
  border: 0.2px solid #42a5f5;
  color: white;
  padding: 16px 14px;
}

.heading1 {
  margin: 1% 0%;
}

.heading2 {
  font-size: 16px !important;
  margin: 30px 0px;
}

.formStyle {
  padding: 2% 4%;
  background-color: black;
  border-radius: 10px;
}

.inputFieldView {
  width: 80%;
  margin: 0 auto;
}

.imgDiv {
  display: flex;
  justify-content: space-between;
  margin: 2% 2% 2% 0%;
}

.imgDiv > div:nth-child(1) {
  flex: 1;
}

.imgDiv > div:nth-child(2) {
  display: flex;
  height: 150px !important;
  width: 160px !important;
  border: 1px solid white;
  margin-left: 10px;
}

.imgDiv img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.firstDiv {
  margin: 2% 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.firstDiv > div {
  width: 48%;
}

.SecondDiv {
  margin: 4% 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.SecondDiv > div {
  width: 48%;
}

.SecondDiv1 > div {
  margin: 6% 0%;
}

.locationDiv {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer !important;
}

.location {
  position: absolute;
  right: 4%;
  color: #42a5f5;
}

.inputDiv1 {
  display: flex;
  flex-direction: row;
  margin: 3% 0%;
  align-items: center;
}

.inputDiv1 > div:nth-child(1) {
  flex: 2;
}

.inputDiv1 > div:nth-child(2) {
  flex: 1;
}

.inputDiv1 > div:nth-child(3) {
  flex: 1;
}

.textCenter {
  align-items: center;
  text-align: center;
  color: #42a5f5;
}

.textCenter input {
  width: 30px;
  height: 30px;
}

.registrationDiv {
  margin: 4% 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.registrationDiv > div {
  width: 48%;
}

.btnDiv {
  display: flex;
  justify-content: flex-end;
}

.btnDiv button {
  justify-content: flex-end;
}

/* New CSS */

/* .EditProfile {
} */

.FormDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* margin-bottom: 1.5%; */
}

.FormDiv > div:nth-child(2) {
  margin-left: 10px;
}

.FormDiv > div {
  flex: 49%;
}

.LabelStyle {
  margin-top: -18px;
  position: absolute;
  margin-left: 30px;
  background-color: black;
  z-index: 10;
  padding-left: 5px;
  padding-right: 5px;
  color: #42a5f5;
  font-size: 10px !important;
}

.AddLink {
  display: flex;
  cursor: pointer;
  /* text-decoration: underline; */
  color: #42a5f5;
}

.UploadDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonUpload {
  display: flex;
  background-color: #42a5f5;
  height: 40px;
  padding: 6px;
  border-radius: 8px;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}
