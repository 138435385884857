.Upload {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1% 0%;
  padding: 2% 0%;
}

.UploadDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.UploadDiv > svg {
  color: #42a5f5;
}
