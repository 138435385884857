.graphContainer {
  display: flex;
  font-size: 14px;
  /* text-transform: uppercase; */
}

.graphContainer .left {
  flex: 1;
  background-color: rgba(30, 30, 30, 1);
  margin-right: 10px;
  padding: 16px;
  border-radius: 10px;
}

.graphContainer .right {
  flex: 4;
  background-color: rgba(30, 30, 30, 1);
  padding: 16px;
  border-radius: 10px;
}

.NodeDiv {
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
}

.NodeDiv > div {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circleNode {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.edgeDiv > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.customArrow {
  border-left: 85px solid transparent;
  border-top: 5px solid transparent;
}

.tableGraph {
  margin-top: 16px;
  padding: 16px;
  background-color: rgba(30, 30, 30, 1);
  border-radius: 10px;
}

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  font-size: 14px !important;
  margin-top: 20px;
}

.Table > thead > tr > td {
  background-color: #343434;
}

.Table > tbody > tr > td:nth-child(1) {
  cursor: pointer !important;
}

.Table > tbody > tr > td:nth-child(1):hover {
  color: #42a5f5;
}
