.Schedules {
  padding-top: 15px;
}

.MilestoneDiv {
  margin-top: 15px;
}

.FormDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.FormDiv > div {
  flex: 49%;
  margin-bottom: 2%;
}
.show {
  max-width: 90vw;
}

.TableContainer {
  /* overflow: scroll; */
  overflow-x: auto;
}

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  font-size: 14px !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

.Table > tbody > tr > td:nth-child(1) {
  cursor: pointer !important;
}

.Table > tbody > tr > td:nth-child(1):hover {
  color: #42a5f5;
}

.actionBTN {
  line-height: 40px;
  background-color: #42a5f5;
  color: white;
  border-radius: 5px;
  border: none;
  width: 155px;
}

.ScheduleBox {
  margin: 2% 0%;
  letter-spacing: 1px;
  background-color: gray;
  color: #fff;
  display: inline-block;
  padding: 1% 2%;
}

.Modal {
  background: #343434 !important;
  color: white !important;
}

.isDisabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  font: inherit !important;
  background: gray !important;
  color: white !important;
  padding: 0.8% 2% !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  outline: none !important;
  border: none !important;
  margin-left: 2%;
  font-size: 14px !important;
  letter-spacing: 1px !important;
}
.button {
  font: inherit !important;
  background: #42a5f5 !important;
  color: white !important;
  padding: 0.8% 2% !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  outline: none !important;
  border: none !important;
  margin-left: 2%;
  font-size: 14px !important;
  letter-spacing: 1px !important;
}

.button:hover,
.button:active {
  background: gray !important;
  color: white !important;
}

.button:focus {
  outline: none !important;
}

.ActionsDiv {
  display: flex;
}

.ActionsDiv > span:nth-child(1) {
  margin-right: 10px;
}

.ActionsDiv > span {
  margin-bottom: 10px;
  cursor: pointer;
  letter-spacing: 1px;
  color: #42a5f5;
}

.ActionsDiv > span:hover {
  opacity: 0.5;
}

.UploadForm {
  padding: 1%;
}

.Upload {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-color: #2e2e2e;
}

.Upload label {
  flex: 1;
}

.UploadDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #d7dadf;
  height: 400px;
}

.UploadDiv > svg {
  color: #42a5f5;
}
