.servicesHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1% 0%;
  align-items: center;
}

.servicesHeader button {
  padding: 6px;
  background-color: #42a5f5;
  color: white;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
}

.servicesHeader button:hover {
  opacity: 0.5;
}

.ButtonDiv button {
  background-color: #42a5f5;
  color: white;
  border-radius: 5px;
  letter-spacing: 1px;
  padding: 4px 10px;
  margin-left: 5px;
  cursor: pointer;
  border: none;
}

.ButtonDiv button:hover {
  opacity: 0.5;
}

.addService {
  padding: 2%;
  margin: 2% 0%;
  border-radius: 5px;
  background-color: black;
  letter-spacing: 1px !important;
}

.backDiv {
  color: #42a5f5;
  margin: 1% 0%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.serviceCategory {
  margin: 0.5% 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.serviceCategory > div:nth-child(1) {
  flex: 20%;
}

.CategoryINP {
  margin: 0.5% 0%;
}

.CategoryINP > input {
  width: 100%;
  padding: 1.2% 0%;
  font-size: 16px;
  background-color: black;
  border: 1px solid #42a5f5;
  outline: none;
  padding-left: 1%;
  color: #42a5f5;
  letter-spacing: 1px;
}

.serviceCategory > div:nth-child(2) {
  flex: 78%;
}

.mapCategory {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #42a5f5;
  margin: 0.5% 0%;
  padding: 1%;
  font-size: 14px;
}

.mapCategory > input[type="checkbox"] {
  height: 30px;
  width: 30px;
}

.confirmBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.confirmBtn > button {
  padding: 1.5% 3%;
  color: #fff;
  background-color: #42a5f5;
  border-radius: 5px;
  letter-spacing: 1px;
  border-radius: 5px;
  cursor: pointer;
}

.headingDiv {
  margin: 3.5% 0%;
}

.Text16 {
  font-size: 16px;
}

.Text15 {
  font-size: 15px;
}

.inputDiv {
  margin: 1% 0%;
}

.inputDiv label {
  margin-top: -20px;
  position: absolute;
  margin-left: 30px;
  background: #010915;
  z-index: 10;
  font-size: 65%;
  padding-left: 5px;
  padding-right: 5px;
  color: #42a5f5;
}

.inputDiv input:not([type="checkbox"]) {
  border-radius: 5px;
  margin-top: -10px;
  width: -webkit-fill-available;
  height: 51px;
  outline: none;
  background: none;
  z-index: -11;
  font-size: 100%;
  border: 0.2px solid #42a5f5;
  color: white;
  padding-left: 20px;
}

.inputDiv textarea {
  border-radius: 5px;
  margin-top: -10px;
  width: -webkit-fill-available;
  height: 92px;
  outline: none;
  background: none;
  font-size: 80%;
  z-index: -11;
  border: 0.2px solid #42a5f5;
  color: white;
  padding: 3%;
}

.ConsumerType {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2% 0%;
}

.ConsumerType > div:nth-child(1) {
  flex: 0.25;
}

.ConsumerType > div:nth-child(2) {
  flex: 0.73;
}

.ConsumerTypeBtn {
  display: flex;
  flex-direction: row;
}

.ConsumerTypeBtn > div {
  margin: 0 2%;
  width: 20%;
  line-height: 35px;
  text-align: center;
  align-items: center;
  color: white;
  background-color: #42a5f5;
  border-radius: 30px;
  clear: both;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
}

.submitBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 1% 0%;
}

.submitBtn > button {
  margin-left: 2%;
  padding: 1.5% 4%;
  border-radius: 5px;
  border: none !important;
  cursor: pointer;
}

.submitBtn > button:nth-child(1) {
  background-color: black;
  color: #42a5f5;
  border: 1px solid #42a5f5 !important;
}

.submitBtn > button:nth-child(2) {
  background-color: #42a5f5;
  color: #fff;
}

.ToogleSelect {
  margin: 1% 0%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.ToogleSelect > div:first-child {
  margin-left: 0%;
}

.ToogleSelect > div:last-child {
  margin-right: 0%;
}

.ToogleSelect > div {
  flex: 1;
  text-align: center;
  margin: 0% 0.5%;
  padding: 0.7% 3px;
  border-radius: 15px;
  color: #fff;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 12px;
}

.ToogleSelect > div:hover {
  background-color: #42a5f5;
}

.isActive {
  background-color: #42a5f5;
}

.notActive {
  background-color: gray;
}

.toogleTab {
  background-color: black;
  margin: 2% 0%;
  padding: 1% 2%;
  border-radius: 10px;
  letter-spacing: 1px;
}

.toogleTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.createService {
  min-width: 750px;
  max-height: fit-content;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05) !important;
  color: white;
}

.createServiceDiv {
  padding: 4%;
}

.createServiceList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2% 0%;
}

.createServiceList > div {
  flex: 30%;
}

.createServiceList2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2% 0%;
}

.createServiceList2 > div {
  flex: 30%;
}

.btnDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2% 0%;
}

.btnDiv > button {
  padding: 1.5% 3%;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  background-color: #42a5f5;
  border: none;
}

.reactSelect {
  color: black;
}

.isDisabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  font: inherit !important;
  background: gray !important;
  color: white !important;
  padding: 0.8% 2% !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  outline: none !important;
  border: none !important;
  margin-left: 2%;
  font-size: 14px !important;
  letter-spacing: 1px !important;
}

/* NEW */

.searchDiv > div:nth-child(1) {
  flex: 3;
  border-right: 2px solid white;
  padding-right: 10px;
}

.searchDiv > div:nth-child(2) {
  flex: 1;
  padding: 10px;
}

.selectDiv {
  flex: 1;
  margin: 5px;
}

.NewButtonDiv button {
  background-color: #42a5f5;
  color: white;
  border-radius: 5px;
  letter-spacing: 1px;
  height: 40px;
  margin-bottom: 5px;
  margin-left: 5px;
  cursor: pointer;
  border: none;
}

.NewButtonDiv button:hover {
  opacity: 0.5;
}
