.Container {
  padding: 2%;
  letter-spacing: 1px;
  background-color: black;
  border-radius: 10px;
  margin-top: 2%;
  font-size: 16px;
}

.heading1 {
  font-size: 120%;
  margin: 1% 0%;
}

.shareDocument {
  padding: 2%;
  letter-spacing: 1px;
  background-color: black;
  border-radius: 10px;
  margin-top: 2%;
  font-size: 16px;
}

.dropDownContainer {
  display: flex;
  flex-direction: row;
}

.dropDownContainer > div:nth-child(1) {
  flex: 20%;
  /* border: 1px solid #42A5F5; */
}

.dropDownContainer > div:nth-child(2) {
  flex: 80%;
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Content > div {
  margin: 0.5% 0%;
}

.inputDiv > input {
  width: 98%;
  height: 50px;
  outline: none;
  background: none;
  border: 1px solid #42a5f5;
  color: white;
  font-size: 125%;
  padding-left: 10px;
  letter-spacing: 1px;
}

.listDiv {
  border: 1px solid #42a5f5;
  margin: 1% 0%;
}

.listDiv1 {
  border: 1px solid #42a5f5;
  margin: 1% 0%;
}

.flexRowJustify {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
  font-size: 100%;
}

.flexRowJustify input[type="checkbox"] {
  width: 30px;
  height: 30px;
}
