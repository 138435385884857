
.Header{
    display: flex;
    justify-content: space-between;
    padding: 1.5% 2%;
    color: white;
    margin-bottom: 5px;
    align-items: center;
    text-align: center;
    background-color: rgb(128, 128, 128);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.Header > div {
  flex: 1;
}


.Column{
    display: flex;
    justify-content: space-between;
    padding: 0% 2%;
    color: white;
    margin-bottom: 5px;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(66, 165, 245);
}

.Column > div{
  flex: 1;
}