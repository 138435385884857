.searchDiv > div:nth-child(1) {
  flex: 3;
  /* border-right: 2px solid white; */
  padding-right: 10px;
}

.searchDiv > div:nth-child(2) {
  flex: 1;
  padding: 10px;
}

.selectDiv {
  flex: 1;
  margin: 5px;
}

.NewButtonDiv button {
  background-color: #42a5f5;
  color: white;
  border-radius: 5px;
  letter-spacing: 1px;
  height: 40px;
  margin-bottom: 5px;
  margin-left: 5px;
  cursor: pointer;
  border: none;
}

.NewButtonDiv button:hover {
  opacity: 0.5;
}
