.dashboardBtnDiv {
  background: rgba(255, 255, 255, 0.05);
  padding: 2%;
  margin: 2% 0%;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.dashboardBtnDiv > button {
  flex: 1;
  margin: 0% 1%;
  cursor: pointer;
  padding: 1% 0%;
  background-color: #42a5f5;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  letter-spacing: 1px;
  border: none;
}

.dashboardContainer {
  background: rgba(255, 255, 255, 0.05);
  padding: 2%;
  font-size: 14px !important;
  border-radius: 10px;
  letter-spacing: 1px;
}

.dashboardWrapper {
  background: black;
  padding: 2%;
  margin-bottom: 2%;
  border-radius: 10px;
}

.dashboardSolution {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.solutionCard {
  flex: 20%;
  margin: 1%;
  background: #1e1e1e;
  border-radius: 10px;
}

.content {
  padding-top: 3px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 6% 0%;
}

.content div {
  font-size: 65px;
}
.content span {
  margin-top: -12px;
}

.content_icons {
  color: #42a5f5;
}

/* Start Alert Library  */

.AlertDiv {
  padding: 10px !important;
}

.AlertDiv button {
  padding: 1rem !important;
}

/* End Alert Library  */

/* Start Dashborad Carousel Library  */

.dashboardCarousel {
  padding: 0rem 2rem !important;
}

.dashboardCarousel > a {
  width: 2.5%;
}

.dashboardCarousel > a:hover {
  color: #42a5f5 !important;
  zoom: 5;
}

.dashboardCarousel > div:nth-child(1) {
  margin: 0% !important;
  bottom: -35px;
}

.dashboardCarousel > div:nth-child(2) {
  color: #42a5f5;
}

/* End Dashborad Carousel Library  */

.carouselWrapper {
  width: 100%;
  letter-spacing: 1px;
  font-size: 14px;
}

.carouselCard {
  border-radius: 10px;
  /* background: rgba(255,255,255,0.05); */
  background: #343434;
  letter-spacing: 1px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.carouselCardLeft {
  flex: 1;
  padding: 1%;
}

.carouselHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid white;
  padding-bottom: 3px;
}

.ratingList {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.carouselRating {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1% 0%;
}

.ratingList > div:nth-child(1) {
  background-color: #42a5f5;
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0px;
}

.ratingList > div:nth-child(2) {
  font-size: 12px !important;
}

.carouselCardRight {
  flex: 1;
  padding: 1%;
  border-left: 1px solid white;
}

.topCategory {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topCategoryDiv {
  flex: 0.48;
  height: 110px;
  padding: 3%;
  border-radius: 5px;
  background: #1e1e1e;
  font-size: 12px;
}

/* .topCategoryLeft{
  padding: 3%;
  border-radius: 5px;
  background: #1E1E1E;
  font-size: 12px;
} */

.topServiceDiv {
  margin: 2% 0%;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.topServiceCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2% 0%;
}

.recommendationsValue {
  font-size: 20px;
  color: #42a5f5;
  display: flex;
  align-items: center;
  height: -webkit-fill-available;
  justify-content: center;
}

.container {
  height: 427px;
  padding: 1%;
  border-radius: 10px;
  background: #343434;
  letter-spacing: 1px;
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
}

.containerLeft {
  border-right: 1px solid white;
  width: 50%;
  padding: 0% 1%;
  display: flex;
  flex-direction: column;
}

.Head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 3px;
  text-align: center;
  border-bottom: 1px solid white;
  font-size: 14px;
}

.ChartDiv {
  flex: 6;
  color: #42a5f5 !important;
  width: 420px;
  height: 300px;
  margin: auto;
}

.Request {
  flex: 2;
  display: flex;
  flex-direction: row;
  font-size: 12px !important;
  justify-content: space-between;
  margin-top: 5px;
}

.ratingDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.ratingDiv > div:nth-child(1) {
  background-color: #42a5f5;
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0px;
}

.ratingDiv > div:nth-child(2) {
  word-break: break-all;
}

.containerRight {
  flex: 1;
  width: 50%;
  padding: 0% 1%;
  font-size: 12px !important;
}

.Column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 2%;
  align-items: center;
  background: #1e1e1e;
  border-radius: 5px;
}

.Column > div {
  flex: 1;
}

.Column > div:nth-child(2) {
  text-align: right;
  color: #42a5f5;
}

.Column > div:nth-child(3) {
  text-align: right;
  color: #42a5f5;
}

.TopListDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.TopListDiv > div {
  width: 49%;
  padding: 2%;
  font-size: 12px;
  background: #1e1e1e;
  border-radius: 5px;
}

.listDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.icon {
  width: 25px;
  height: 25px;
}

.topDetails {
  display: flex;
  flex-direction: column;
}

.topDetailsTitle {
  flex: 1;
}

.topDetailsValue {
  flex: 3;
  display: flex;
  justify-content: center;
  color: #42a5f5;
  align-items: center;
  font-size: 16px;
}

.subTitle {
  font-size: 14px;
}

.Participant {
  font-size: 16px;
  color: #42a5f5;
}

.colorBlue {
  color: #42a5f5;
}

.subtype {
  font-size: 10px;
}

.terms {
  letter-spacing: 1px;
  word-spacing: 1px;
}

.termInputDiv {
  margin: 20px 0px;
}

.termNote {
  color: red;
  display: flex;
  align-items: center;
}

.termNote input {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-right: 10px;
}
