.Label {
  font-size: 14px !important;
  letter-spacing: 1px !important;
  display: flex;
}

.Label > div {
  display: flex;
  flex-direction: column;
}

.Label span {
  margin: 0px 20px 2px 0px;
}
