/* .LinkBuyer {
  padding: 20px 10px 20px 10px;
  letter-spacing: 1px;
  font-size: 23px;
}

.LinkBuyerButton {
  display: flex;
  justify-content: flex-end;
}

.LinkBuyerButton {
  display: flex;
  justify-content: flex-end;
}

.LinkBuyerForm {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 25px;
  margin: 10px 0px;
  background-color: black;
  border-radius: 10px;
}

span {
  letter-spacing: 2px;
  margin: 0px 5px;
}

.LinkBuyerFormBtn {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
}

.flexbox {
  display: flex;
  justify-content: space-between;
}
.mb-10 {
  margin-bottom: 10px;
  display: flex;
}
.mr-4 {
  flex: 0.25;
}

.mr-6 {
  flex: 0.69;
} */


/* Details.jsx */

.navbar {
  padding-bottom:20px;
}
.navbar > li {
  cursor: pointer;
}
.navbar > li:hover {
  color:#218eeb;
}

.btnDiv{
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.btnDiv > button{
  border:none;
   letter-spacing:1px;
   padding: 10px 60px;
   background-color:#42A5F5;
   color: white;
   font-size:16px;
   border-radius:5px;
   margin: 5px;
   cursor: pointer;
}