.upload-file-card {
    padding: 16px;
    background: #000000;
    border-radius: 10px;
    border-bottom: 27px solid rgba(255, 255, 255, 0.05);
}

.upload-file-card button {
    min-width: 50px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-transform: capitalize;
    background: #ffffff;
    padding: 12px 45px;
    color: #42a5f5;
    border: none;
    border-radius: 5px; 
}

.upload-button:hover {
    opacity: 0.5;
    cursor: pointer;
  }

.upload-section {
    padding: 40px;
    margin: 16px;
    background-color: #2e2e2e;
}

.upload-action {
    display: flex;
    justify-content: end;
}

.upload-action > button {
    margin-right: 30px;
}