.UploadBtn {
  border: 0px;
  width: 100px;
  padding: 5px 10px;
  color: white;
  background-color: #42a5f5;
  border-radius: 5px;
}

.UploadForm {
  padding: 1%;
}

.Upload {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-color: #2e2e2e;
}

.Upload label {
  flex: 1;
}

.UploadDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #d7dadf;
  height: 400px;
}

.UploadDiv > svg {
  color: #42a5f5;
}

.ButtonNext {
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  width: 100px;
  color: white;
  background-color: #42a5f5;
}
