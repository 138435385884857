.Wrapper {
  padding: 2%;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  letter-spacing: 1px;
}

.headerDiv {
  display: flex;
  justify-content: space-between;
}

.InputDiv {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;
}

.InputDiv input {
  padding: 8px;
  outline: none;
  border-radius: 5px;
  width: 300px;
  letter-spacing: 1px;
}

.clearFilterDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  cursor: pointer;
  color: #42a5f5;
}

.searchDiv {
  display: flex;
  letter-spacing: 1px;
  font-size: 14px;
  align-items: center;
  /* margin-top: 20px; */
}

.searchDiv > div {
  flex: 1;
  margin-right: 10px;
}

.headerDiv button {
  border: none;
  padding: 5px 10px;
  background-color: #42a5f5;
  color: white;
  border-radius: 5px;
}

.FormDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.FormDiv > div {
  width: 49.5%;
  margin-bottom: 2%;
}

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  font-size: 14px !important;
  white-space: nowrap !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

.Table > tbody > tr > td:nth-child(1) {
  cursor: pointer !important;
}

.Table > tbody > tr > td:nth-child(1):hover {
  color: #42a5f5;
}
