.Wrapper {
  padding: 2%;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  letter-spacing: 1px;
  font-size: 16px;
}

.Container {
  padding: 2%;
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Content {
  background-color: black;
  border-radius: 10px;
  /* padding: 2%; */
}

.FlexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1% 0%;
}

.uploadInvoiceBtn {
  padding: 0.5%;
  background-color: #42a5f5;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.headDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1% 0%;
}

.headTitle {
  display: flex;
  align-items: center;
  color: #42a5f5;
}

.SearchDiv > div {
  flex: 32% 1;
  max-width: 33%;
}

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  font-size: 14px !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

.Table > tbody > tr > td:nth-child(1) {
  cursor: pointer !important;
}

.Table > tbody > tr > td:nth-child(1):hover {
  color: #42a5f5;
}

.toogleTab {
  background-color: black;
  margin-bottom: 2%;
  padding: 1% 2%;
  border-radius: 10px;
  letter-spacing: 1px;
}

.toogleTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.serviceSummary {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.serviceSummary > div:nth-child(1) {
  flex: 2;
  /* padding: 0px 0 10px; */
  padding-left: 10px;
}

.serviceSummary > div:nth-child(2) {
  flex: 3;
  padding-left: 20px;
}

.serviceSummary > div:nth-child(3) {
  flex: 2;
  padding-left: 20px;
}

.serviceSummary > div:nth-child(1) {
  border-right: 2px solid;
}

.serviceSummary > div:nth-child(2) {
  border-right: 2px solid;
}

.summaryHead {
  display: flex;
}

.summaryHead1 {
  display: flex;
  font-size: 45px;
  margin-right: 12px;
}

.summaryHead2 {
  display: flex;
  flex-direction: column;
}

.summaryAll {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.summaryList {
  flex: 49%;
  padding: 10px;
}

.summaryList > span:nth-child(1) {
  margin-right: 10px;
}

.iconValue {
  position: absolute;
  font-size: 16px;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
}
