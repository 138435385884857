.column{
    display: flex;
    flex-wrap: wrap;
    border-color: red;
}

.columnList {
    display: flex;
    flex: 47%;
    flex-direction: column;
    background: #1e1e1e;
    border-radius: 10px;
    margin: 0.5%;
}

.columnContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5%;
    padding: 0% 1%;
    font-size: 16px !important;
    letter-spacing: 1px !important;
}

