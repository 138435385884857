.searchDiv > div {
  flex: 2;
  text-align: center;
  align-items: center;
  margin: 0px 2px;
  border-radius: 30px;
  padding: 0px 1px;
  clear: both;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  text-transform: uppercase;
}
.searchDiv > div:hover {
  background: #42a5f5;
}

.activeDiv {
  background: #42a5f5;
  color: white;
  padding: 10px 0px !important;
  width: 100%;
}
.nonActiveDiv {
  border: 1px solid #42a5f5;
  color: white;
  padding: 10px 0px !important;
  width: 100%;
}

.inputSearch {
  flex: 4;
  margin: 0px 2px;
  height: 41px;
  border: none;
  margin-left: 5px;
  border-radius: 5px;
  padding-left: 5px;
  font-size: 20px;
}
.inputSearch > input:focus {
  outline: none;
}

.wrapper {
  padding: 20px 16px;
  background-color: black;
  border-radius: 10px;
  margin-bottom: 15px;
  flex: 1;
  text-align: center;
}

.tableContainer {
  padding: 10px;
}

.collapsible {
  background-color: #343434;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
