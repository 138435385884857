.Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.imgDiv {
  width: 100px;
  height: 100px;
  color: white;
}

.imgDiv img {
  width: 100%;
  height: 100%;
}
