.Header {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 2px;
}

.LogoDiv {
  display: flex;
  justify-content: start;
  align-items: center;
  transition: all 2.5s ease-in-out;
  line-height: 0;
  scroll-behavior: smooth;
}

.Logo1 {
  width: 213px;
  transition: all 0.5s ease-in-out;
}

.Logo2 {
  width: 52px;
  transition: all 0.5s ease-in-out;
}

.bars {
  display: flex;
  font-size: 36px;
  font-weight: 700;
  margin-left: 10px;
}

.Node {
  font-size: 12px;
  color: #42a5f5 !important;
  letter-spacing: 1px !important;
}

.inviteStyle {
  font-size: 16px !important;
  color: #42a5f5 !important;
  letter-spacing: 1px !important;
  font-weight: normal !important;
  display: flex;
  align-items: center !important;
  margin-right: 15px !important;
  cursor: pointer;
}

.inviteStyle svg {
  margin-right: 5px;
}

.inviteStyle2 {
  font-size: 14px !important;
  color: #42a5f5 !important;
  letter-spacing: 1px !important;
  font-weight: normal !important;
  display: flex;
  align-items: center !important;
  margin-right: 15px !important;
  cursor: pointer;
}

.inviteStyle2 svg {
  margin-right: 5px;
}

.navContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 2%;
}

.navContainer svg {
  margin-left: 15px;
  color: #42a5f5 !important;
}

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20%;
  height: 35%;
  position: absolute;
  top: 32px;
  right: 30px;
  background: black;
  border-radius: 10px;
  z-index: 3;
  letter-spacing: 1px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding-bottom: 1%;
  transition: all 0.3s linear;
}

.FaUserCircle {
  margin-left: 15px;
  cursor: pointer;
}

.FaUserCircle img {
  margin-top: 5px;
  border-radius: 50%;
}

.dropdown svg {
  color: #42a5f5 !important;
}

.opacity {
  opacity: 0.5;
}

.firstDiv {
  display: flex;
  flex-direction: row;
  padding: 5% 0%;
  text-align: center;
  border-bottom: 1px solid #d3d3d3;
}

.firstDiv1 {
  flex: 1;
  align-items: center;
}

.firstDiv1 img {
  margin-top: 5px;
  border-radius: 50%;
}

.firstDiv2 {
  flex: 3;
}

.firstDiv2 div:nth-child(1) {
  text-align: left;
}

.firstDiv2 div:nth-child(2) {
  text-align: left;
}

.secondDiv {
  display: flex;
  flex-direction: row;
  padding: 2% 0%;
  cursor: pointer;
}

.secondDiv div:nth-child(1) {
  flex: 1;
}

.secondDiv div:nth-child(2) {
  flex: 3;
  text-align: left;
}
