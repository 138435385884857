.ShipmentSummary {
  font-size: 12px !important;
  letter-spacing: 1px !important;
}

.AddTradeBtnDiv {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.AddTradeBtnDiv > button {
  border: none;
  width: 100px;
  height: 40px;
  letter-spacing: 1px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #42a5f5;
  color: white;
}

.ShipmentSummary > div:nth-child(1) {
  background-color: #1e1e1e;
  padding: 2%;
  border-radius: 10px;
}

.Steppers {
  padding: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px !important;
}

.SteppersFrom {
  flex: 10%;
  align-items: center;
  text-align: center;
  display: flex;
}

.SteppersTo {
  flex: 10%;
  align-items: center;
  text-align: center;
  display: flex;
}

.fromText {
  text-align: right;
  margin-right: 5px;
  white-space: nowrap;
  padding-top: 20px;
}

.toText {
  text-align: left;
  margin-left: 5px;
  white-space: nowrap;
  padding-top: 20px;
}

.inconDiv {
  position: relative;
}

.inconDiv1 {
  position: absolute;
  top: -28px;
  right: -3px;
  /* color: #42a5f5; */
}

.footerIcon {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.footerIcon > div {
  margin-left: 10px;
}

.SteppersMiddle {
  flex: 80%;
  background: linear-gradient(to right, orange 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-repeat: repeat-x, repeat-y;
  background-size: 6px 2px, 1px 10px;
  display: flex;
  justify-content: space-between;
  margin-top: -22px;
  color: #42a5f5;
}

.SteppersMiddle span {
  margin: 0px 0px 5px 0px;
}

.Steppers2 {
  padding: 0% 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: #42a5f5; */
  font-size: 12px !important;
}

.Steppers2 > div:nth-child(1) {
  flex: 10%;
  text-align: right;
}

.Steppers2Middle {
  flex: 80%;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.Steppers2Middle > div {
  flex: 80%;
  display: flex;
  justify-content: center;
}

.Steppers2 > div:nth-child(3) {
  flex: 10%;
  text-align: left;
}

.ShippingSchedule {
  background-color: #1e1e1e;
  padding: 2%;
  border-radius: 10px;
}

.ButtomDiv {
  display: flex;
  margin-top: 2%;
  background-color: #1e1e1e;
  padding: 1%;
  border-radius: 10px;
}

.ButtomDiv .header {
  font-size: 18px;
  margin-bottom: 2%;
}

.Left {
  flex: 70%;
  /* border: 1px solid #42a5f5; */
  padding: 15px;
}

.Right {
  flex: 30%;
  /* border: 1px solid #42a5f5; */
  padding: 15px;
}

.ShippingRouteHead {
  font-size: 18px;
  margin-bottom: 10px;
}

.VerticalContent {
  display: flex;
  position: relative;
  margin-bottom: 46px;
}

.VerticalContentAfter1::after {
  content: ". . . .";
  position: absolute;
  bottom: -32px;
  left: -9px;
  transform: rotate(90deg);
  font-size: 30px;
  font-weight: 900;
  /* color: #28a745 !important; */
}

.step1::after {
  color: #ffc107 !important;
}

.step2::after {
  color: #42a5f5 !important;
}

.step3::after {
  color: #218838 !important;
}

.VerticalContentAfter2::after {
  content: ". . . .";
  position: absolute;
  bottom: -32px;
  left: -9px;
  transform: rotate(90deg);
  font-size: 30px;
  font-weight: 900;
  color: #ffc107 !important;
}

.VerticalContent > div:nth-child(1) {
  flex: 1;
}

.VerticalContent > div:nth-child(2) {
  flex: 8;
  white-space: nowrap;
}

/* Start MultiStatusContainer */
.MutliContent {
  background-color: #1e1e1e;
  padding: 3%;
  margin-top: 2%;
  border-radius: 10px;
}

.MutliContent .header {
  font-size: 18px;
  margin-bottom: 2%;
}

.VerticalContentMutli {
  display: flex;
  flex-direction: column;
}

.VerticalContentMutli > div:not(:last-child) {
  height: 90px;
  border-left: 2px solid white;
  border-left-width: 5px;
}

.ContentMutli {
  display: flex;
  margin-left: -15px;
  margin-top: -7px;
}

.HRStyle {
  border: 1px solid #42a5f5;
  /* margin-left: 25px; */
}

.newMultiContent {
  display: flex;
}

.newMultiContent > div:nth-child(1) {
  flex: 2;
  text-align: right;
  margin-right: 20px;
}

.newMultiContentA {
  height: 118px;
  border-left: 2px solid white;
  border-left-width: 5px;
}

.newMultiContentB {
  height: 90px;
  border-left: 2px solid #1e1e1e;
  border-left-width: 5px;
}

.newMultiContent > div:nth-child(2) {
  flex: 1;
}

.newMultiContent > div:nth-child(3) {
  flex: 20;
}
