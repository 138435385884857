.button {
  background: #42a5f5 !important;
  color: white !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  outline: none !important;
  border: none !important;
  margin-left: 10px;
  font-size: 16px !important;
  padding: 6px 12px;
  letter-spacing: 1px !important;
}

.disabled {
  background: gray !important;
  color: white !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  outline: none !important;
  border: none !important;
  margin-left: 10px;
  font-size: 16px !important;
  padding: 6px 12px;
  letter-spacing: 1px !important;
  pointer-events: none;
}

.button:hover,
.button:active {
  background: gray !important;
  color: white !important;
}

.button:focus {
  outline: none !important;
}
