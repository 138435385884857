.buttonDiv {
  margin-bottom: 10px;
  font-size: 14px !important;
}

.buttonDiv button {
  background-color: #42a5f5;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-left: 10px;
  padding: 6px 5px;
}

.buttonDiv button:hover {
  opacity: 0.5;
}

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  font-size: 14px !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

.Wrapper {
  padding: 2%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  letter-spacing: 1px;
  font-size: 16px;
}

.documents {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 2%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.previewNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.05);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 2px solid white;
}

.previewNav > div {
  padding: 1.5% 2%;
  cursor: pointer;
  font-size: 120%;
}

.CompanyName {
  padding: 1%;
  background-color: #42a5f5;
  color: white;
  display: inline-block;
  margin-bottom: 1%;
  border-radius: 5px;
}

.DocumentNavbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1% 1% 0% 1% !important;
  margin-bottom: 0px !important;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.DocumentNavbar > div {
  flex: 1;
  text-align: center;
  padding: 1.2% 1.2% 1.2% 1.2%;
}

.DocumentNavbar > div:hover {
  color: #218eeb;
  cursor: pointer;
}

.active {
  color: #218eeb !important;
  font-size: 100% !important;
  border-bottom: 2px solid #218eeb !important;
}

.notActive {
  color: white !important;
  font-size: 100% !important;
  border-bottom: 2px solid white !important;
}

.Menu {
  display: flex;
  flex-direction: row;
  padding: 2% 2% 0% 2%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

.Menu > div {
  cursor: pointer;
}

.Menu > div:last-child {
  margin-left: 2%;
}

.Back {
  cursor: pointer;
  color: #42a5f5;
}

.MultiSelectDiv {
  display: flex;
}

.MultiSelectDiv > span {
  flex: 1;
}

.MultiSelectDiv > input {
  flex: 1;
  outline: none;
  border: none;
  font-size: 16px;
  padding-left: 1%;
  padding-right: 1%;
  height: 44px;
  border-radius: 5px;
}

/* .Wrapper */

.dashboardWrapper {
  background: black;
  padding: 2%;
  margin-bottom: 2%;
  border-radius: 10px;
}

.dashboardTitle {
  font-size: 110%;
  /* margin: 1% 0%; */
}

.dashboardSolution {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.solutionCard {
  flex: 20%;
  margin: 1%;
  background: #1e1e1e;
  border-radius: 10px;
}

.content {
  padding-top: 3px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 6% 0%;
}

.content div {
  font-size: 65px;
}
.content span {
  margin-top: -12px;
}

.content_icons {
  color: #42a5f5;
}

.iconDiv > span {
  margin: 0 4px;
  cursor: pointer;
  color: #42a5f5;
}

.Checkbox {
  width: 25px;
  height: 25px;
}

.Upload {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1% 0%;
  padding: 2% 0%;
}

/* .UploadDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.UploadDiv > svg {
  color: #42a5f5;
} */

.isDisabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.UploadForm {
  padding: 1%;
}

.Upload {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-color: #2e2e2e;
}

.Upload label {
  flex: 1;
}

.UploadDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #d7dadf;
  height: 400px;
}

.UploadDiv > svg {
  color: #42a5f5;
}
