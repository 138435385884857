.header {
  /* font-size: 25px; */
  margin-top: 2%;
}
.source {
  display: flex;
  flex-direction: row;
  flex: content;
}
.Checkbox {
  width: 25px;
  height: 25px;
  margin-top: 2%;
  margin-left: 2%;
}

.radio {
  width: 18px;
  height: 18px;
  /* margin-right: 0.2%; */
  margin-top: 0.5%;
}

.loads {
  padding: 2%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  letter-spacing: 1px;
}

.TableContainer {
  /* width: 1000px; */
  overflow: scroll;
}

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  font-size: 14px !important;
  white-space: nowrap !important;
  font-size: 14px !important;
}
