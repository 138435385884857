.container {
  flex: 1;
}

.containerWrapper {
  padding: 20px 16px;
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  flex: 1;
}

.actionBarStyled {
  flex: 1;
  padding: 15px 15px 0px 15px !important;
  margin-bottom: 0px !important;
}

/* Start -- company */
.heading1 {
  font-weight: 400;
  font-size: 20px;
  line-height: 15px;
  color: #ffffff;
  margin-left: 10px;
  margin-top: 15px;
}

.Card {
  /* padding: 20px 0px 20px 0px; */
  background: black;
  /* border: 1px solid red; */
  border-radius: 0px 0px 10px 10px;
  align-items: center;
  border-radius: 10px;
  /* margin-right: 20px; */
}
.companyTable {
  /* align-items: center; */
  margin: 0px auto;
}
.cardHeader {
  padding: 10px 10px;
}
.CardWrapper {
  display: flex;
}
.buttonDiv {
  letter-spacing: 2px;
  background-color: black;
  display: flex;
  margin: 10px 5px;
  color: white !important;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  /* border: 1px solid rgb(120, 235, 120); */
}
.buttonStyle {
  flex: 0.24;
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0px;
  border-radius: 5px;
  background: #1e1e1e;
  border: 2px solid #218eeb;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.buttonStyle:hover {
  background: #218eeb;
}

.buttonStyleActive {
  flex: 0.24;
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0px;
  border-radius: 5px;
  background: #42a5f5;
  border-radius: 5px;
  color: white;
}
.tableWrapper {
  padding: 5px 15px;
  max-height: 250px;
  overflow-y: scroll;
}

/* End --  company */

/* starting --  share document */
.Recipient {
  margin-top: 2%;
  font-weight: 400;
  font-size: 20px;
  line-height: 15px;
  color: #ffffff;
  margin-left: 1%;
}
.row {
  /* display: flex; */
  /* width: 100%; */
  /* margin-top: 54px; */
  /* margin-left:150px; */
  /* margin-top:-20px; */
  display: flex;
  width: 100%;
  /* margin-top: 54px; */
  margin-left: 100px;
  /* margin-top: -20px; */
  margin-right: 20px;
}
.recipientBox {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.column1 {
  width: 100%;
  height: 100%;
  background: #474747;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}
.field {
  display: flex;
  width: 100%;
  align-items: center;
  /* border:1px solid red; */
}
.result {
  margin-left: 20px;
}
.crossbtn {
  margin-left: -45px;
  padding: 0px;
  background: none;
  /* border: none; */
  color: none;
  cursor: pointer;
  /* border:1px solid  red; */
}
.overInputDiv {
  width: 100%;
  /* overflow-x: scroll;
  overflow-y: scroll; */
  /* border:1px solid rgb(51, 197, 7); */
  /* ::-webkit-scrollbar{
    display: none;
  } */
}
.inputdiv {
  display: flex;
  width: 97.5%;
  height: 51px;
  background: rgb(170, 170, 170);
  align-items: center;
  border-radius: 5px;
  margin-top: 14px;
  /* overflow-x: scroll;
  overflow-y: scroll; */
  /* ::-webkit-scrollbar{
    display: none;
  } */
}
.inputdiv input {
  width: 140px;
  height: 30px;
  margin: 10px;
  background: #010915;
  color: #ffffff;
  border-radius: 25px;
  padding-left: 10px;
}
.resultfield {
  display: flex;
  background: rgb(170, 170, 170);
  height: 49px;
  border-radius: 2px;
}
.innerInputDiv {
  overflow-x: scroll;
  background: rgb(170, 170, 170);
  width: 750px;
  /* ::-webkit-scrollbar{
    display: none;
  } */
}
.searchInput {
  margin-top: 10px;
  display: flex;
  background: white;
  width: 97.5%;
  border-radius: 6px;
}
.searchInput span {
  color: black;
  margin-top: 12px;
  align-items: center;
  position: absolute;
  margin-left: 0.5%;
}

.searchInput input {
  border-radius: 5px;
  height: 40px;
  padding-left: 5%;
  font-size: 18px;
  width: 92%;
  border: none;
  color: black;
}

/* End --  share Document */

/* start of document activity */
.documentActi {
  margin-top: 4%;
  background: black;
}

/* end of document activity */

/* starting of digital signature */
.digitalContainer {
  background: #051123;
  margin-top: 2%;
  padding: 2%;
  overflow: scroll;
}

.heading2 {
  font-weight: 400;
  font-size: 20px;
  line-height: 75px;
  color: #ffffff;
  margin-left: 2%;
}
.inputdivDigital {
  margin-left: 80px;
}

/* .signature {
} */

.signature > div:nth-child(1) {
  padding: 0% 2%;
}

.signatureCol1 > div {
  margin-bottom: 4%;
  display: flex;
  align-items: center;
  text-align: center;
}

.signature > div:nth-child(3) {
  padding: 0% 2%;
}

.signatureColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textRight {
  display: flex;
  justify-content: flex-start;
}

.shareDocGrid > div {
  margin-bottom: 2%;
}

.ButtonReq {
  border: none;
  padding: 1.5% 3%;
  color: #fff;
  background-color: #42a5f5;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
}

.ButtonReq:hover {
  background-color: grey;
}

@media (min-width: 900px) and (max-width: 1250px) {
  .container {
    width: 100%;
  }
}

/* End of digital signature */

@media (min-width: 666px) and (max-width: 899px) {
  .container {
    padding: 15px !important;
    margin: 0 auto;
  }
  .containerWrapper {
    padding: 15px;
    flex: 1;
  }
  .actionBarStyled {
    font-size: 14px;
    letter-spacing: 1px;
    padding: 15px 15px 0px 15px !important;
    margin-bottom: 0px !important;
  }

  /* Start --  company */
  .heading1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }
  .Card {
    width: 40%;
  }
  .buttonDiv {
    /* display: flex;
     flex-direction: column; */
    width: 40%;
  }
  .buttonStyle {
    display: flex;
    flex-direction: row;
  }

  /* share document */
  .recipientBox {
    display: flex;
    align-items: center;
    /* width: 100%; */
  }
  /* end of share document */
}

@media (min-width: 450px) and (max-width: 665px) {
  .container {
    padding: 12px !important;
  }
  .containerWrapper {
    padding: 12px;
    flex: 1;
  }
  .actionBarStyled {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 15px 15px 0px 15px !important;
    margin-bottom: 0px !important;
  }

  /* Start --  Overview */
  .heading1 {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  .Card {
    width: 25%;
  }
}

@media (min-width: 350px) and (max-width: 449px) {
  .container {
    padding: 12px !important;
  }
  .containerWrapper {
    padding: 12px;
    flex: 1;
  }
  .actionBarStyled {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 10px;
    letter-spacing: 1px;
    padding: 15px 15px 0px 15px !important;
    margin-bottom: 0px !important;
  }
  .card {
    display: flex;
    flex: 100%;
  }

  /* Start --  company */
  .heading1 {
    font-weight: 400;
    font-size: 10px;
  }
  .Card {
    width: 20%;
  }
  .buttonDiv {
    display: flex;
    flex-direction: column;
  }
  .buttonStyle {
    margin-top: 10px;
  }
}
