/* .Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  font-size: 14px !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

.Table > tbody > tr > td:hover {
  color: #42a5f5 !important;
} */

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  font-size: 14px !important;
  overflow-x: scroll;
  overflow-inline:scroll ;
  scrollbar-width: 12px;
  /* scrollbar-color: #888 #f1f1f1; */
}


.Table > thead > tr > td {
  background-color: #343434;
}

.Table > tbody > tr > td:nth-child(1) {
  cursor: pointer !important;
}

.Table > tbody > tr > td:nth-child(1):hover {
  color: #42a5f5;
}

.FilterDiv {
  margin-bottom: 1%;
}

.FilterDiv > div {
  flex: 1;
  max-width: 33%;
  /* border: 1px solid red; */
}

.action {
  cursor: pointer;
}

.action:hover {
  color: #42a5f5;
}
