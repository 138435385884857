.changeButtom {
    background-color: rgb(66, 165, 245);
    border: none;
    padding: 1%;
    width: 120%;
    border-radius: 5px;
    padding-top: 8%;
    padding-bottom: 8%;
    color: white;
    font-size: 14px;
}

.changeButtom:hover {
    opacity: 0.5;
}

.participantListTableButton {
    height: 40px;
    margin-right: 2%;
    padding: 1%;
    background-color: #42A5F5;
    border: none;
    border-radius: 5px;
    color: white;
}

.participantListTableButton:hover {
    opacity: 0.5;
}
.createPayment:hover{
    opacity: 0.5;
}
.createPayment{
    border: none;
    color: white;
    font-size: 12px;
}
.headerHover{
    background: transparent;
    background-color: transparent;
    width: 20%;
    color: white;
    border: none;
}
.headerHover:hover{
    color: #42A5F5;
    background: transparent;
    background-color: transparent;
    width: 20%;
    border: none;
}
