.ToogleNavbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  margin-top: 1%;
  font-size: 14px !important;
}

.ToogleNavbar > div {
  flex: 1;
  padding: 1% 0%;
  cursor: pointer;
}

.navbarComponent {
  margin-top: 2%;
}

.isActive {
  color: #42a5f5;
  font-size: 110% !important;
  border-bottom: 2px solid #42a5f5;
}
.isActive:hover{
  color: #42a5f5;
}

.notActive {
  color: white;
  font-size: 100% !important;
  border-bottom: 2px solid white;
}
.notActive:hover{
  color: #42a5f5;
}
