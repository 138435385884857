.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  font-size: 14px !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

/* .Table > tbody > tr > td:hover {
  color: #42a5f5 !important;
} */

.Checkbox {
  width: 20px;
  height: 20px;
}

.ActionButton {
  border: none;
}

.Modal {
  background: #343434 !important;
  color: white !important;
}

/* /Summary */

.column {
  display: flex;
  flex-wrap: wrap;
}

.columnList {
  display: flex;
  flex: 47%;
  flex-direction: column;
  background: #1e1e1e;
  border-radius: 10px;
  margin: 0.5%;
}

.columnContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5%;
  padding: 0% 1%;
  font-size: 16px !important;
  letter-spacing: 1px !important;
}

.ButtonDiv {
  display: flex;
}

.ButtonDiv > button {
  flex: 1;
  border: none;
  outline: none;
  padding: 1%;
  color: #fff;
  background-color: #42a5f5;
  letter-spacing: 1px;
}

.ButtonDiv > button:hover {
  background-color: gray;
}
