.upperDiv{
    padding: 2%;
    font-size: 16px;
    background-color:rgba(255,255,255,0.05);
    border-radius: 10px;
    letter-spacing: 1px;
    margin: 1% 0%;
}

.upperDiv svg{
   font-size: 300%;
}

.middleDiv{
    padding: 2%;
    background-color: #1E1E1E;
    border-radius: 10px;
}

.heading1{
    font-size: 120%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.lowerDiv{
     padding: 2%;
     background-color: #000000;
     border-radius: 10px;
}

.flexRowJustify{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.card{
   text-align: center;
   background-color: #1E1E1E;
   width: 20%;
   /* margin: 1.5%; */
   padding: 1%;
   border-radius: 10px;
}