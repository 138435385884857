.searchDiv {
  border: 1px solid #42a5f5;
  padding: 10px;
  display: flex;

  justify-content: space-between;
}

.searchDiv > div:nth-child(1) {
  flex: 0.4;

  border: 1px solid #42a5f5;
}

.filterDiv {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
}

.filterDiv > div {
  border-radius: 5px;
  background: #42a5f5;
  padding: 10px 25px;
  margin: 10px;
  flex: 1;

  letter-spacing: 1px;
}

.companyType {
  color: rgba(255, 161, 0, 1);
}
.supplierType {
  color: #bf40bf;
}

.searchDiv > div:nth-child(2) {
  flex: 0.4;
  border: 1px solid #42a5f5;
}
.inputSearchDiv {
  padding: 10px;
}
.buttonDiv {
  align-self: flex-end;
}

.buttonDiv > button {
  padding: 10px 30px;

  background-color: #42a5f5;
}

/* Global */
.flexCenter {
  display: flex;

  align-items: center;
}

/* End Global */

.profileWrapper {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 2%;
  padding: 2%;
  font-size: 14px;
  letter-spacing: 1px;
  background: rgba(30, 30, 30, 1);
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0px;
}

.certficateIconDiv div:nth-child(2) {
  margin-left: 5px;
}

.flexStarting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
}

.discription {
  margin-bottom: 15px;
}

.iconNaming {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.profileWrapper > div:nth-child(1) {
  flex: 1;
  border-right: 2px solid rgba(0, 0, 0, 1);
}

.profileContainer {
  display: flex;
}

.imgDiv {
  flex: 0.3;
  margin-right: 20px;

  display: flex;
  flex-direction: column;
}

.imgDiv > img {
  width: 140px;
  height: 120px;
}

.profileDiv {
  flex: 0.69;
  letter-spacing: 1px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.profileWrapper > div:nth-child(2) {
  flex: 1;
  padding: 0px 15px;
  letter-spacing: 1px;
}

.btnDiv {
  display: flex;
  margin-bottom: 15px;

  justify-content: space-between;
}

.btnDiv > button {
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px !important;
  width: 200px;
  max-width: 200px;
  display: inline-block;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.deleteBtn {
  background-color: rgb(233, 111, 111);
}
.addBtn {
  background-color: #42a5f5;
}

.detailsDiv {
  display: flex;
  justify-content: space-between;
}

.addressDiv {
  display: flex;
  flex: 1;
}

.phoneDiv {
  flex: 1;

  align-items: flex-end;
  justify-content: flex-end;
  text-align: end;
}

.phoneContainer {
  display: flex;
}

.mapDiv {
  display: flex;
  justify-content: space-between;

  margin-bottom: -5px;
}

.mapDiv > div:nth-child(1) {
  display: flex;

  align-items: center;
}

.icon_box svg {
  margin-left: 25px;
  cursor: pointer;
}

.NodeDiv {
  display: flex;
  flex-direction: row;
  padding-right: 1%;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.NodeText {
  color: #42a5f5;
}

.iconDiv svg {
  cursor: pointer;
}

.iconDiv svg:nth-child(2) {
  margin-left: 10px;
}

.namingDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  padding-top: 0px;
  cursor: pointer;
  font-size: 15px !important;
  color: #42a5f5;
}

.mapDiv > Div:nth-child(2) {
  display: flex;

  align-items: center;
}

.mapDiv > Div:nth-child(2) svg {
}

.profileWrapper1 {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 10px 0px;

  padding: 20px;
  background: rgba(30, 30, 30, 1);
  letter-spacing: 1px;
}
.profileWrapper1 span {
  font-size: 14px !important;
}

.lastDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px;

  letter-spacing: 1px;
}

.lastDiv > div:nth-child(1) {
  flex: 0.49;

  padding: 20px;
  border-radius: 5px;
}

.lastDiv > div:nth-child(2) {
  flex: 0.49;

  padding: 20px;
  border-radius: 5px;
}

/* .title1 {
  font-size: 18px;
}
.title2 {
  font-size: 16px;
  margin: 5px 0px;
}

.title3 {
  font-size: 16px;
  margin: 5px 0px;
} */

.issuedList {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}
.requestCopy {
  text-transform: uppercase;
  color: #42a5f5;
}

.noRecord {
  text-transform: uppercase;
  color: #42a5f5;
  letter-spacing: 1px;
}

.isDisabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}
