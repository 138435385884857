.ModalHeader {
  background: #343434 !important;
  color: white !important;
}

.ModalHeader button {
  color: white;
}

.ModalBody {
  background: #343434 !important;
  color: white !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.label {
  color: #42a5f5;
}

.input {
  border-radius: 5px;
  width: -webkit-fill-available;
  /* height: 92px; */
  outline: none;
  background: none;
  border: 0.2px solid #42a5f5;
  color: white;
  padding: 10px;
}
