/* .center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%;
}
.font {
  font-family: Mulish, sans-serif;
  font-size: 16px;
}
.card {
  max-width: 300px;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
}

.card a {
  text-decoration: none;
}

.content {
  padding: 1.1rem;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 150px;
  background-color: rgb(239, 205, 255);
}

.title {
  color: #111827;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}

.desc {
  margin-top: 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.action {
  display: inline-flex;
  margin-top: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  align-items: center;
  gap: 0.25rem;
  background-color: #2563eb;
  padding: 4px 8px;
  border-radius: 4px;
}

.action span {
  transition: 0.3s ease;
}

.action:hover span {
  transform: translateX(4px);
}

.profileWrapper {
  display: flex;
  border-radius: 5px;
  display: block;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  padding: 2%;
  font-size: 14px;
  margin-top: "15";
  margin-left: "4%";
  margin-right: "4%";
  letter-spacing: 1.5px;
  background: rgba(30, 30, 30, 1);
}

.profileWrapper:hover {
  transform: scale(1.03);
}

.profileWrapper1 {
  display: flex;
  border-radius: 20px;
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  padding: 2%;
  font-size: 14px;
  margin-top: "15";
  margin-left: "4%";
  margin-right: "4%";
  letter-spacing: 1.5px;

  background: rgba(0, 132, 255, 1);
  &:hover:before {
    transform: scale(1.03);
  }
}

.profileWrapper1:hover {
  transform: scale(1.03);
}
.card1 {
  width: 100%;
  height: 150px;
  border-radius: 20px;
  padding: 5px;
  text-align: center;

  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
}

.card__content {
  background: rgb(5, 6, 45);
  border-radius: 17px;
  text-align: center;

  width: 100%;
  height: 100%;
} */

.profileWrapper {
  display: flex;
  border-radius: 5px;
  display: block;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  padding: 2%;
  font-size: 14px;
  margin-top: "15";
  margin-left: "4%";
  margin-right: "4%";
  letter-spacing: 1.5px;
  background: rgba(30, 30, 30, 1);
}

/* .profileWrapper:hover {
  transform: scale(1.03);
} */

.profileWrapper a {
  color: #42a5f5;
  text-decoration: none;
}

.profileWrapper a:hover {
  color: #42a5f5;
  opacity: 0.8;
}

.icon path{
  stroke: #fff ;
}