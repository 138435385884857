.UserManagement {
  display: grid;
  grid-template-columns: 49% 49%;
  gap:  20px;
}
/* .InputList {
  margin: 2% 0%;
} */

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  font-size: 14px !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

/* .Table > tbody > tr > td:hover {
  color: #42a5f5 !important;
} */
