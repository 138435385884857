.Dialog {
  /* border: 1px solid red; */
  /* padding: 2%; */
  background: rgba(255, 255, 255, 0.05);
  /* border-radius: 10px; */
  /* letter-spacing: 1px; */
  /* font-size: 16px; */
}

.btnClick {
  padding: 1.5% 3%;
  color: #fff;
  background-color: #42a5f5;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
}

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  font-size: 14px !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

.iconDiv > span {
  margin: 0 4px;
  color: #42a5f5;
  cursor: pointer;
}

.Checkbox {
  width: 25px;
  height: 25px;
}

.Header {
  margin-bottom: 10px;
  font-size: 14px !important;
}

.Header > div {
  flex: 1;
}

.buttonDiv button {
  background-color: #42a5f5;
  color: #fff;
  border: none;
  outline: none;
  white-space: nowrap;
  border-radius: 5px;
  padding: 5px;
  height: 40px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.buttonDiv button:hover {
  background-color: gray;
}

.isDisabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.ActionDiv {
  display: flex;
  /* background-color: black; */
  border-radius: 5px;
  /* padding: 10px; */
}

.ActionDiv > div:nth-child(1) {
  flex: 2;
}

.ActionDiv > div:nth-child(2) {
  flex: 1;
}

.ActionDiv > div:nth-child(3) {
  flex: 2;
}

.openAction {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #42a5f5;
  letter-spacing: 1px;
}

.openAction > span {
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.openAction > span:hover {
  opacity: 0.5;
}

.isDisabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}
