.inputWrapper {
  flex: 48%;
}

.inputWrapper1 {
  font-size: 14px !important;
}

.inputWrapper1 input {
  font-size: 14px !important;
}

/* .inputWrapper1 label {
  top: -5px;
} */

.inputStyle {
  width: 100%;
  margin: 5px 0;
  border-radius: 5px;
  font-size: 14px;
}
