.Container {
  padding: 2%;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  letter-spacing: 1px;
  font-size: 16px;
}

.Wrapper {
  padding: 2%;
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  letter-spacing: 1px;
  font-size: 16px;
}

.Content {
  background-color: black;
  border-radius: 10px;
  padding: 2%;
  /* margin-bottom: 2%; */
}

.ButtonDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  margin: 1% 0%;
}
