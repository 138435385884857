.ServiceCard {
  background-color: rgb(30, 30, 30);
  padding: 2%;
  border-radius: 10px;
  font-size: 14px;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
}

.companyName {
  color: #42a5f5;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 16px;
  width: 32%;
}

.ServiceHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1% 0%;
}

/* .ServiceHead1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 30em;
} */

.Checkbox {
  width: 25px;
  height: 25px;
}

.service-head-left > button {
  background-color: #42a5f5;
  border: none;
  width: 150px;
  height: 25px;
  color: white;
  border-radius: 15px;
}

.service-head-right {
  display: flex;
  flex-direction: row;
  justify-content: end;
  letter-spacing: 1px;
  align-items: center;
}

.service-head-right > button {
  background-color: #42a5f5;
  border: none;
  min-width: 200px;
  height: 35px;
  padding: 0% 2%;
  color: white;
  border-radius: 5px;
}

.service-head-right > input {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.supplier-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
}

.supplier-text > div:nth-child(1) {
  font-size: 15px;
}

.ServiceBody {
  display: flex;
  flex-direction: row;
}

.ServiceBody > div:nth-child(1) {
  flex: 1;
  border-right: 2px solid black;
  padding-right: 1%;
  display: flex;
  flex-direction: column;
}

.ServiceBody > div:nth-child(2) {
  flex: 2;
  padding-left: 2%;
}

.flexbox-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* font-size: 16px; */
}

.flexbox-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 15px !important;
  margin-bottom: 2%;
}

.iconDiv {
  margin-left: 10px;
}

.iconDiv > svg {
  cursor: pointer;
}

.checkIcon {
  color: #42a5f5 !important;
}

.icon-group {
  margin-top: auto;
  color: #42a5f5;
}

.icon-group > svg {
  margin-right: 10px;
}

.link {
  text-decoration: underline;
  font-size: 12px !important;
  color: blue !important;
  cursor: pointer;
  letter-spacing: 1px;
}

.supplier {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 2% 0%;
}

.supplier > div:nth-child(1) {
  flex: 1;
  font-size: 15px !important;
}

.supplierList {
  flex: 3;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px !important;
}

.supplierList > button {
  background-color: #42a5f5;
  border: none;
  min-width: 32%;
  height: 30px;
  color: white;
  border-radius: 15px;
  margin-right: 1%;
  margin-bottom: 2%;
}

.isDisabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}
.ServiceBtn2{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  letter-spacing: 1px;
  align-items: center;
  align-content: center;
}
.ServiceBtn2 > button{
  background-color: #42a5f5;
  border: none;
  min-width: 200px;
  height: 35px;
  padding: 0% 2%;
  color: white;
  border-radius: 5px;
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.8 !important;
}
.ServiceBtn2 > button:hover{
  background: #42a5f5;
  cursor: default;
  
}
