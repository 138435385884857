.backNavigate {
  display: flex;
  color: #42a5f5;
  align-items: center;
  letter-spacing: 1px;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 10px 0px;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* padding: 10px; */
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Lato", sans-serif;
}
.containe1 {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  z-index: 9999;
}

.navigateToEdit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #42a5f5;
  cursor: pointer;
  letter-spacing: 1px;
  margin: 0px 5px 10px 0px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin: 10px 0px;
  padding: 20px;
  height: 200px;
  background: rgba(30, 30, 30, 1);
}

.wrapper > div:nth-child(1) {
  flex: 1;
  border-right: 2px solid rgba(0, 0, 0, 1);
}

.wrapper > div:nth-child(2) {
  flex: 1;
  padding: 0px 15px;
  letter-spacing: 1px;
  /* font-size: 15px; */
}

.leftWrapper {
  display: flex;
  padding: 0px 15px;
  /* flex-direction: column; */
}

.imgDiv {
  margin-right: 20px;
  height: 150px !important;
  width: 160px !important;
  border: 1px solid white;
}

.imgDiv > img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.profileDiv {
  flex: auto;
  letter-spacing: 1px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.namingDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  padding-top: 0px;
  font-size: 15px;
  color: #42a5f5;
  height: 22px;
}

.companyType {
  color: rgba(255, 161, 0, 1);
}

.supplierType {
  color: purple;
}

.certficateIconDiv div:nth-child(2) {
  margin-left: 5px;
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* font-size: 15px; */
}

.discription {
  /* font-size: 15px; */
  margin-bottom: 15px;
}

.iconDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -2px;
}

.iconDiv svg:nth-child(1) {
  margin-left: 25px;
  cursor: pointer;
}

.iconDiv svg:nth-child(2) {
  margin-left: 25px;
  cursor: pointer;
}

.rightWrapper {
  display: flex;
  padding: 0px 15px;
  flex-direction: column;
}

.btnDiv {
  display: flex;
  flex: 1;
  margin-bottom: 15px;
  /* font-size: 15px; */
  justify-content: space-between;
}

.btnDiv > button {
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px !important;
  width: 200px;
  max-width: 200px;
  display: inline-block;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex: 3;
  justify-content: space-between;
}

.deleteBtn {
  background-color: rgb(233, 111, 111);
  /* font-size: 15px; */
}
.addBtn {
  background-color: #42a5f5;
  /* font-size: 15px; */
}

.detailsDiv {
  display: flex;
  justify-content: space-between;
  /* font-size: 15px; */
}

.addressDiv {
  display: flex;
  flex: 1;
  /* font-size: 15px; */
}

.phoneDiv {
  /* flex: 1; */
  /* font-size: 15px; */
  align-items: flex-end;
  justify-content: flex-end;
  text-align: end;
}

.phoneContainer {
  display: flex;
  /* font-size: 15px; */
}

.mapDiv {
  display: flex;
  justify-content: space-between;
  /* font-size: 15px; */
  margin-bottom: -5px;
  align-items: flex-end;
}

.mapDiv > div:nth-child(1) {
  display: flex;
  /* font-size: 15px; */
  align-items: center;
}

/* a{
 text-decoration: none !important;
} */

.icon_box svg {
  margin-left: 25px;
  cursor: pointer;
}

.iconDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -2px;
}

.iconDiv svg:nth-child(1) {
  margin-left: 25px;
}

.iconDiv svg:nth-child(2) {
  margin-left: 25px;
}

.Wrapper1 {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 10px 0px;
  /* font-size: 18px; */
  padding: 20px;
  background: rgba(30, 30, 30, 1);
  letter-spacing: 1px;
}

.Wrapper1 span {
  /* font-size: 16px !important; */
}

.Wrapper2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px;
  letter-spacing: 1px;
}

.Wrapper2 > div:nth-child(1) {
  flex: 0.49;
  padding: 20px;
  border-radius: 5px;
  background: rgba(30, 30, 30, 1);
  height: max-content;
}

.Wrapper2 > div:nth-child(2) {
  flex: 0.49;
  padding: 20px;
  border-radius: 5px;
  background: rgba(30, 30, 30, 1);
  height: max-content;
}

.issuedList {
  display: flex;
  flex-direction: row;
  letter-spacing: 1px;
  justify-content: space-between;
  /* font-size: 14px; */
  opacity: 0.7;
  font-weight: 300;
}
.requestCopy {
  text-transform: uppercase;
  color: #42a5f5;
  opacity: 1 !important;
  font-weight: 400 !important;
}

.performanceCard {
  border-radius: 8px;
  padding: 2%;
  margin: 2% 0%;
  background: rgba(30, 30, 30, 1);
  letter-spacing: 1px;
}

.performanceHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.iconDIv {
  margin-right: 10px;
}

.title1 {
  /* font-size: 18px; */
}

.title2 {
  /* font-size: 16px; */
}

.performanceBody {
  border-radius: 8px;
  margin: 1% 0%;
  background: black;
  display: flex;
  flex-direction: row;
}

.performanceBody1 {
  flex: 1;
  padding: 2%;
  border-right: 2px solid rgba(30, 30, 30, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.performanceValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2% 0%;
}

.title3 {
  /* font-size: 14px; */
  opacity: 0.5;
  font-weight: 300;
}

.colorBlue {
  color: #42a5f5;
  /* font-size: 24px; */
  /* word-break: keep-all; */
}

.linkMember {
  color: #42a5f5;
  /* font-size: 12px; */
  text-decoration: underline;
  cursor: pointer;
  align-items: flex-end;
}

.performanceBody2 {
  flex: 2;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.performanceTop1 {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
  align-items: center;
}

.performanceTop1 div:nth-child(1) {
  flex: 3;
  word-break: break-all;
}

.trade {
  flex: 2;
  word-break: break-all;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin: 0% 1%;
}

.title4 {
  /* font-size: 16px; */
  opacity: 0.5;
}

.performanceTop2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4% 0%;
}

.performanceTop2 button {
  padding: 1.5% 4%;
  background-color: #42a5f5;
  color: #fff;
  border: none;
  /* font-size: 14px; */
  border-radius: 25px;
}

.performanceFooter {
  font-size: 10px;
}

.trustBody1 {
  flex: 1;
  border-right: 2px solid rgba(30, 30, 30, 1);
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trustBody2 {
  flex: 1;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trustCardRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.trustCardRight1 {
  flex: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trustCardRigh2 {
  flex: 25%;
}

.trustValue {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-right: 3%;
  justify-content: space-between;
  margin: 2% 0% 2% 0%;
}

.tradeIconDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding: 2% 0% 0% 4%;
}

.tradeIconDiv > div {
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
}

.performanceBodyNew {
  border-radius: 8px;
  padding: 2%;
  margin: 2% 0%;
  background: black;
  letter-spacing: 1px;
}

.performanceGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.performanceGrid > div {
  flex: 49%;
  /* margin-bottom: 2%; */
  padding: 0% 1%;
}

.performanceGrid > div:nth-child(odd) {
  border-right: 2px solid rgba(30, 30, 30, 1);
}

/* RegistrationDiv */

.registrationDiv {
  display: flex;
  flex-direction: row;
}

.registrationDiv > div {
  flex: 1;
  /* font-size: 90%; */
  padding: 1% 1%;
}

.registrationDiv > div:nth-child(1) {
  border-right: 2px solid black;
}

.registrationList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.colorBlue2 {
  color: #42a5f5;
}

.documentDiv {
  /* border: 1px solid #42A5F5; */
  background-color: black;
  border-radius: 5px;
  padding: 2%;
  margin: 1.5% 0%;
  font-size: 14px !important;
}

.viewBtnDiv {
  /* border: 1px solid #42A5F5; */
  padding: 1%;
}

.viewBtnDiv > button {
  padding: 1% 4%;
  background-color: #42a5f5;
  color: #fff;
  border-radius: 5px;
  border: none;
  letter-spacing: 1px;
  cursor: pointer;
}

.ServiceCardDiv {
  padding: 2%;
  border-radius: 5px;
  background: black;
  height: max-content;
}

.isDisabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}
