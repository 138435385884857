.dashboardWrapper {
  background: black;
  padding: 2%;
  margin-bottom: 2%;
  border-radius: 10px;
}

.dashboardTitle {
  font-size: 110%;
}

.dashboardSolution {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.dashboardSolution > div {
  margin: 2%;
  background: #1e1e1e;
  border-radius: 10px;
}

.content {
  padding-top: 3px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 6% 0%;
}

.content div {
  font-size: 65px;
}

.content span {
  margin-top: -12px;
}

.content_icons {
  color: #42a5f5;
}

.iconDiv > span {
  margin: 0 4px;
  color: #42a5f5;
}
