.collapsible {
  background-color: rgb(0, 0, 0);
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.formbox
{
  position: relative;
}
.trancheBtnBox{
  position: absolute;
  right: 0;
  top: -76px;
}
.innerpadding .collapsible {
  padding:10px 18px;
}
.content {
  padding: 0 18px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
}

.wrapper {
  padding: 20px 16px;
  background-color: black;
  border-radius: 10px;
  margin-bottom: 15px;
  flex: 1;
  text-align: center;
}
.boxsteper{
  background: #4e4c4c;
  padding: 30px;
  border-radius: 4px;
}
.containerWrapper {
  padding: 20px 16px;
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  flex: 1;
}

.tableContainer {
  padding: 10px;
}
.loanSummary{
  background: #1e1e1e;
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
}
.loanSummary h2{
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.card {
  display: flex;
  flex: 47%;
  flex-direction: column;
  background: #000;
  border-radius: 10px;
  margin: 5px 10px 5px 0px;
}
.messagenotify{
  display: flex;
  flex: 100%;
  background: #1e1e1e;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  color: rgb(13, 72, 161);
}
.contents {
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem;
  letter-spacing: 1px;
 
}
/* End --  Overview */

/* Start --  Invoice */
.invoiceHeader {
  padding: 20px 16px;
  background-color: black;
  border-radius: 10px;
  margin-bottom: 15px;
  flex: 1;
}
.invoiceTopHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.heading2 {
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  margin: 15px 0px;
}
.active {
  background-color: blue;
}
.rowbox{
  display: flex;
  width: 100%;
}