.collepse{
    width: 100%;
    background-color:black; 
    border-radius:5px;
    display: flex;
    flex-direction:column;
    align-items: center;
    padding-top: 1rem;
}
.notCollepse{
    width: 100%;
    background-color:black; 
    border-radius:5px;
    display: flex;
    flex-direction:column;
    align-items: center;
    padding-top: 1rem;
}
.headerHandbugger{
}
.headerHandbuggerDiv{
    padding: 12px 12px 12px 12px;
    margin-bottom:1rem;
}
.headerHandbuggerNotCollepse{

}
.headerHandbuggerNotCollepseDiv{
    padding: 12px 12px 12px 12px;
    margin-bottom:1rem;
    display:flex;
}

hr.new1{
    color:white;
    width: 100%;
    margin: 0rem;
}

.navItemCollepse{
    width:100%;
    cursor: pointer;
}
.navItemCollepseDiv{
    padding:12px 12px 12px 12px;
    width:100%;
    text-align: center;
}
.navItemNotCollepse{   
    width:100%;
}
.navItemNotCollepseDiv{
     padding:12px 12px 12px 12px;
    display:flex;
    width:100%;
    cursor: pointer;
}
.navItemCollepse :hover{
    background-color: #42a5f5;
}
.headerHandbugger :hover{
    background-color: #42a5f5;
}
.navItemNotCollepse:hover{
    background-color: #42a5f5;
}
.headerHandbuggerNotCollepse > div > div :hover{
   color: #42a5f5;
}
.link{
    text-decoration: underline;
    background: transparent;
    border: none;
    color: #2180D1;
}
.link:hover{
    color: #42a5f5;
    text-decoration: underline;

}
.subscribedButton {
    background-color: "#2180D1";
    height: "2.5rem";
    border-radius: "5px";
    display: "flex";
    justify-content: "center";
    align-items: "center";
    font-size: "90%";
    padding: "0px 10px";
    width: "100%";
}
.subscribedButton:hover{
    opacity: 0.5;
}
