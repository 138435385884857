.QuickFilters {
  justify-content: space-between;
}

.QuickFilters > div {
  flex: 1;
  max-width: 24%;
}

.icon {
  margin-left: 5px;
  color: #42a5f5;
}

.TableContainer {
  max-width: 100%;
  overflow-x: auto;
}

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  font-size: 14px !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}
