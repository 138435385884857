@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&display=swap");

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  /* font-weight: 300 400 700 900; */
}
.boxsteper {
  background: #4e4c4c;
  padding: 30px;
  border-radius: 4px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 30px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0d6efd;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0d6efd;
  border-radius: 30px;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(255 255 255 / 55%);
}
.tranchlistdata {
  padding: 0px 20px;
  border: 1px solid #504f4f;
  margin-top: -5px;
  position: relative;
}
.innerpadding {
  padding: 20px 0;
}
