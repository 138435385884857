.StatusDiv {
  margin-top: 10px;
}

.StatusDiv button {
  background-color: #42a5f5;
  color: white;
  padding: 7px 20px;
  margin-left: 10px;
  border-radius: 5px;
}

.StatusDiv button:hover {
  opacity: 0.5;
}

.StatusDiv span {
  color: #ffc107;
}
.FilterData {
  margin-top: 2%;
  display: flex;
  flex-wrap: wrap;
}

.FilterData > div {
  flex: 19%;
  text-align: center;
  padding: 0.5%;

  border-radius: 30px;
  height: 40px;
  cursor: pointer;
  margin: 0% 0.5%;
  margin-bottom: 1% !important;
}

.activeToggle {
  background-color: #42a5f5;
  text-align: center;
}

.notActiveToggle {
  background-color: gray;
}

.FilterData > div:hover {
  background-color: #42a5f5;
}

.TopDiv {
  margin-bottom: 1%;
  display: flex;
}

.TopDiv > div {
  flex: 1;
  max-width: 33%;
}

.InputDiv > input {
  padding: 1% 2%;
  border-radius: 5px;
  outline: none;
  border: none;
  letter-spacing: 1px;
  height: 40px;
}

.action {
  background-color: #42a5f5;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  height: 35px;
  text-align: center;
}

.action:hover {
  opacity: 0.5;
}

.Table {
  color: white !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
  text-align: center !important;
  font-size: 14px !important;
  white-space: nowrap !important;
}

.Table > thead > tr > td {
  background-color: #343434;
}

.Table > tbody > tr > td:nth-child(1) {
  cursor: pointer !important;
}

.Table > tbody > tr > td:nth-child(1):hover {
  color: #42a5f5;
}
.Checkbox {
  width: 20px;
  height: 20px;
}

.FormDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.FormDiv > div {
  flex: 49%;
  margin-bottom: 2%;
}

/* AccountSchedule */

.AccountSchedule {
  margin-bottom: 1%;
  color: #42a5f5;
  cursor: pointer;
  text-decoration: underline;
}

/* /Summary */

.column {
  display: flex;
  flex-wrap: wrap;
}

.columnList {
  display: flex;
  flex: 47%;
  flex-direction: column;
  background: #1e1e1e;
  border-radius: 10px;
  margin: 0.5%;
}

.columnContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5%;
  padding: 0% 1%;
  font-size: 14px !important;
  letter-spacing: 1px !important;
}

.SearchDiv > div {
  flex: 1;
  max-width: 33%;
  font-size: 14px !important;
  margin-right: 10px;
}

.toogleTab {
  background-color: black;
  margin-bottom: 2%;
  padding: 2%;
  border-radius: 10px;
  letter-spacing: 1px;
}

.toogleTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}



.isDisabled {
  background: #42a5f5 !important;
  color: white !important;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  cursor: pointer !important;
  border-radius: 5px !important;
  outline: none !important;
  border: none !important;
  margin-left: 10px;
  font-size: 16px !important;
  padding: 6px 12px;
  letter-spacing: 1px !important;
}


.button {
  font: inherit !important;
  background: #42a5f5 !important;
  color: white !important;
  padding: 0.8% 2% !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  outline: none !important;
  border: none !important;
  margin-left: 2%;
  font-size: 14px !important;
  letter-spacing: 1px !important;
}

.button:hover,
.button:active {
  background: gray !important;
  color: white !important;
}

.button:focus {
  outline: none !important;
}
.StatusDiv {
  margin-top: 10px;
}

.StatusDiv button {
  background-color: #42a5f5;
  color: white;
  padding: 7px 20px;
  margin-left: 10px;
  border-radius: 5px;
}

.StatusDiv button:hover {
  opacity: 0.5;
}

.StatusDiv span {
  color: #ffc107;
}

