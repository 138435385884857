/* Global */
.flexCenter {
  display: flex;
  align-items: center;
}

/* End Global */

.profileWrapper {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin: 10px 0px;
  padding: 20px;
  background: rgba(30, 30, 30, 1);
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0px;
  font-size: 14px;
  line-height: 26px;
}

.discription {
  font-size: 14px;
}
.profileWrapper > div:nth-child(1) {
  flex: 1;
  border-right: 2px solid rgba(0, 0, 0, 1);
}
.profileContainer {
  display: flex;
  padding-right: 10px;
}
.imgDiv {
  flex: 0.3;
  margin-right: 20px;
}
.imgDiv > img {
  width: 140px;
  height: 120px;
}
.profileDiv {
  flex: 0.69;
  letter-spacing: 1px;
}
.profileWrapper > div:nth-child(2) {
  flex: 1;
  padding: 5px;
  letter-spacing: 1px;
}
.btnDiv {
  display: flex;
  justify-content: flex-end;
  height: 60px;
  display: none;
}
.btnDiv > button {
  border: none;
  width: 206px;
  display: none;
  background-color: #42a5f5;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  margin: 6px;
}

.iconStyle {
  margin: 0px 10px;
}

.detailsDiv {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 18px;
  height: 130px;
}
.checkbox {
  width: 100px;
  height: 100px;
}
.addressDiv {
  display: flex;
  flex: 3;
}
.phoneDiv {
  flex: 1;
  font-size: 15px;
  margin-left: 50px;
}
.phoneContainer {
  display: flex;
}
.mapDiv {
  display: flex;
  margin-left: 2px;
  justify-content: space-between;
  font-size: 15px;
  align-items: center;
  margin-top: 25px;
}
.mapDiv > Div:nth-child(1) {
  display: flex;
  align-items: center;
}

.mapDiv > Div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 30px;
}

.mapDiv > Div:nth-child(2) svg {
  font-size: 15px;
}
.mapDivDetails {
  display: flex;
  margin-left: 10px;
  justify-content: space-between;
  font-size: 15px;
  align-items: center;
  margin-top: 70px;
}

.mapDivDetails > Div:nth-child(1) {
  display: flex;
  align-items: center;
}
.mapDivDetails > Div:nth-child(2) {
  display: flex;
  gap: 30px;
  align-items: center;
}
.mapDivDetails > Div:nth-child(2) svg {
  font-size: 15px;
}
.mapSocialMedia {
  display: flex;
  justify-content: flex-end;
  margin-top: 54px;
}
.profileWrapper1 {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 10px 0px;
  padding: 20px;
  background: rgba(30, 30, 30, 1);
  letter-spacing: 1px;
}
.profileWrapper1 span {
  font-size: 14px !important;
}

.lastDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px;
  letter-spacing: 1px;
}

.lastDiv > div:nth-child(1) {
  flex: 0.49;
  /* border: 1px solid #42A5F5; */
  background: rgba(30, 30, 30, 1);
  padding: 20px;
  border-radius: 5px;
}

.lastDiv > div:nth-child(2) {
  flex: 0.49;
  /* border: 1px solid #42A5F5; */
  background: rgba(30, 30, 30, 1);

  padding: 20px;
  border-radius: 5px;
}

.title1 {
  font-size: 18px;
}
.title2 {
  font-size: 15px;
  margin: 5px 0px;
}

.title3 {
  font-size: 14px;
  margin: 5px 0px;
}
.issuedList {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: space-between;
}
.requestCopy {
  text-transform: uppercase;
  color: #42a5f5;
}

.onboardingStatus {
  display: flex;
  align-items: center;
  margin: 5px 0px;
}

.onboardingStatus > span:nth-child(2) {
  margin: 0px 20px;
  color: yellow;
}

.onboardingStatus > span:nth-child(3) {
  padding: 6px;
  background-color: #42a5f5;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.onboardingStatus > span:nth-child(3):hover {
  opacity: 0.5;
}

.ButtonDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.ButtonDiv button {
  padding: 6px;
  min-width: 100px;
  background-color: #42a5f5;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  color: white;
  margin-left: 15px;
  letter-spacing: 1px;
}

.ButtonDiv button:hover {
  opacity: 0.5;
}
